//import { axios } from '@/api'; 
import http from "."; 

class ImprimirTermicaServices { 
  Imprimir( datos) { 
    console.log(datos)
    return http.post("termica/imprimir", datos)
      .catch((error) => {
        /*if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }*/
      });
  }

  Impresoras() {
    return http.get("termica/impresoras")
      .catch((error) => {
        /*if (error.response.status == 401) {
          store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
          router.push("/pages/login");
        }*/
      });
  }
}


export default new ImprimirTermicaServices();