<template>
    <div class="text-center">
        <v-dialog v-model="dialog" :fullscreen="fullscreen" transition="dialog-bottom-transition"
            :width="width ? width : 1000">
            <template v-if="btnVisible">
                <v-btn color="red lighten-2"   @click="abrirModal()">
                    Click Me
                </v-btn>
            </template>

            <v-card class="grey1">
                <v-toolbar    height="50" class="mb-4">
                    <v-btn icon @click="dialog = false">
                        <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                    <v-toolbar-title><small>Busqueda avanzada</small> </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn small class="mt-2" color="primary" @click="Aceptar()">
                            Aceptar
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
            

                <v-card-text>
                    <busqueda-producto-avanzado ref="BusquedaProductoAvanzadoRef" @GetProductos="GetProductos"
                        :columnas="columnas" :IndExistencia="IndExistencia" :seleccionMultiple="seleccionMultiple"
                        :soloExistencia="soloExistencia" :idArea="idArea"
                            :indLote="indLote"
                        ></busqueda-producto-avanzado>
                </v-card-text>

                <v-divider></v-divider>


            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import BusquedaProductoAvanzado from './BusquedaProductoAvanzado.vue';
import { mdiClose } from '@mdi/js'
export default {
    components: { BusquedaProductoAvanzado },
    props: {
        btnVisible: Boolean,
        fullscreen: Boolean,
        width: Number,
        columnas: {
            type: Array,
            default: ['ID', 'NOMBRE', 'CODIGO', 'CARACTERISTICA', 'CATEGORIA', 'LINEA', 'EXISTENCIA']
        },
        soloExistencia: {
            type: Boolean,
            default: true
        },
        IndExistencia: {
            type: Boolean,
            default: true
        },
        idArea: Number,
        indLote: {
            type: Boolean,
            default: true
        },
    },
    setup(props, context) {
        const dialog = ref(false)
        const seleccionMultiple = ref(true)
        const BusquedaProductoAvanzadoRef = ref(null)
        const abrirModal = (seleccionMultipleIn) => {

            seleccionMultiple.value = seleccionMultipleIn ? seleccionMultipleIn : false
            dialog.value = true
            BusquedaProductoAvanzadoRef.value.Limpiar()

            

        }
        const Aceptar = () => {
            BusquedaProductoAvanzadoRef.value.GetProductos()
        }
        const GetProductos = (datos) => {
            context.emit("GetProductos", datos)
            dialog.value = false

        }

        return {
            dialog,
            abrirModal,
            seleccionMultiple,
            icons: {
                mdiClose
            },
            GetProductos,
            BusquedaProductoAvanzadoRef,
            Aceptar
        }
    },
}
</script>