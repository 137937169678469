<template>

    <div class="text-center">
        <v-dialog v-model="dialogModal" width="1000">
            <template v-slot:activator="{ on, attrs }" v-if="btnVisible">
                <v-btn :disabled="disabled" block color="primary" v-bind="attrs" v-on="on">
                    Forma pago <v-icon right dark>
                        {{ icons.mdiKeyboardF5 }}
                    </v-icon>
                </v-btn>
            </template>

            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6" >
                            <v-card  >
                                <v-container>
                                    <v-row>
                                        <v-bottom-navigation v-model="pago.moneda" class="pt-2" color="success"
                                            background-color="transparent"  >
                                            <v-btn @click="MonedaSelect(item)" v-for="(item, index) in monedasFilter()"
                                                :key="index" :value="item"   class="mx-2">
                                                <span>{{ item.nombre }}</span>
                                                {{ item.simbolo }}
                                            </v-btn>
                                        </v-bottom-navigation>
                                    </v-row>
                                    <v-row>
                                        <v-col class="text-center py-0 my-0">
                                            <v-btn class="ma-2 "
                                                :color="item.id == pago.formaPago ? 'primary' : 'secondary'" small
                                                v-for="(item, index) in pago.moneda.formaPago" :key="'btn-' + index"
                                                @click="SelectFormaPago(item)">
                                                {{ item.descripcion }}
                                            </v-btn>
                                        </v-col>

                                    </v-row>
                                </v-container>

                            </v-card >
                            <v-row>
                                <v-col cols="12">

                                    <v-container>
                                        <v-row class="mt-2 ">
                                            <v-col cols="12" v-if="indFecha">
                                                <v-dialog ref="dialog" v-model="modal" :return-value.sync="pago.fecha"
                                                    persistent width="290px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field dense outlined v-model="pago.fecha" label="Fecha"
                                                            hide-details readonly v-bind="attrs"
                                                            v-on="on"></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="pago.fecha" scrollable locale="es-VE">
                                                        <v-spacer></v-spacer>
                                                        <v-btn text color="primary" @click="modal = false">
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn text color="primary"
                                                            @click="$refs.dialog.save(pago.fecha)">
                                                            OK
                                                        </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>
                                            </v-col>
                                            <v-col :cols="tasaIgualDolar() ? '12' : '6'" class="my-0 py-0">


                                                <v-text-field-money ref="montoMonedaRef" v-model="montoPagoMoneda"  
                                                     v-bind:label="'Monto ' + pago.moneda.simbolo"
                                                    v-bind:properties="{
                                                        prefix: pago.moneda.simbolo,
                                                        readonly: false,
                                                        disabled: false,
                                                        outlined: true,
                                                        clearable: true,
                                                        hint: descripcionTasa(),
                                                        persistentHint: true,
                                                        dense: true, 
                                                    }" v-bind:options="{
    locale: 'es-Ve',
    length: 11,
    precision: 2,
    empty: null,
}" />


                                            </v-col>
                                            <v-col cols="6" class="my-0 py-0" v-if="!tasaIgualDolar()">

                                                <v-text-field-money v-model="montoPagoReal" v-bind:label="'Monto '+ monedaPais.simbolo "
                                                    v-on:keyup.enter="AgregarPago()" v-bind:properties="{
                                                        prefix: '$',
                                                        readonly: false,
                                                        disabled: false,
                                                        outlined: true,
                                                        clearable: true,
                                                        persistentHint: true,
                                                        dense: true,
                                                    
                                                    }" v-bind:options="{
    locale: 'es-Ve',
    length: 11,
    precision: 2,
    empty: null,
}" />



                                            </v-col>
                                            <v-col cols="12" class="my-0 py-0">
                                                <v-text-field ref="ReferenciaRef" label="Referencia" placeholder="ref"
                                                    outlined v-model="pago.referencia" dense></v-text-field>
                                            </v-col>
                                            <v-col cols="12" class="my-0 py-0">
                                                <v-btn color="primary" @click="AgregarPago()" small block> agregar
                                                </v-btn>
                                            </v-col>
                                        </v-row>


                                    </v-container>

                                </v-col>
                                <v-divider vertical></v-divider>
                                <v-col cols="12" class="mt-5 pt-5"
                                    :align-self="(valorMaximo != -1 && valorMaximo !== undefined) ? 'end' : 'end'">
                                    <v-simple-table dense>
                                        <template>
                                            <tbody>
                                                <tr v-if="valorMaximo != -1 && valorMaximo !== undefined">
                                                    <td> <strong>Total:</strong></td>
                                                    <td class="text-right">

                                                        {{funciones.monedaPrincipal().simbolo}} {{ valorMaximo.toFixed(2) }}

                                                    </td>
                                                </tr>
                                                <tr v-if="(valorMaximo != -1 && valorMaximo !== undefined) && configuracion.igtf >0">
                                                    <td><strong>IGTF:</strong></td>
                                                    <td class="text-right">

                                                        {{funciones.monedaPrincipal().simbolo}} {{ TotalPagoIgtf().toFixed(2) }}

                                                    </td>
                                                </tr>
                                                <tr v-if="(valorMaximo != -1 && valorMaximo !== undefined) && false" >
                                                    <td><strong>Total a pagar:</strong></td>
                                                    <td class="text-right">

                                                        {{funciones.monedaPrincipal().simbolo}} {{ (valorMaximo + TotalPagoIgtf()).toFixed(2) }}

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Pagos:</strong></td>
                                                    <td class="text-right">

                                                        {{funciones.monedaPrincipal().simbolo}} {{ (TotalPago()).toFixed(2) }}
                                                    </td>
                                                </tr>
                                                <tr v-if="valorMaximo != -1 && valorMaximo !== undefined">
                                                    <td>
                                                        <strong
                                                            v-if="(valorMaximo - TotalPago() + TotalPagoIgtf()).toFixed(2) >= 0">Resta:</strong>
                                                        <strong v-else>Vuelto:</strong>
                                                    </td>
                                                    <td class="text-right">

                                                        <span :class="(valorMaximo - TotalPago() + TotalPagoIgtf()).toFixed(2) > 0 ? 'error--text':'success--text'"
                                                            v-if="(valorMaximo - TotalPago() + TotalPagoIgtf()).toFixed(2) >= 0">
                                                            
                                                            {{funciones.monedaPrincipal().simbolo}} 
                                                            {{ (valorMaximo - TotalPago() +
                                                            TotalPagoIgtf()).toFixed(2)}}</span>


                                                        <span class="success--text" v-else>
                                                            {{funciones.monedaPrincipal().simbolo}} {{ (-1 * (valorMaximo - TotalPago() +
                                                        TotalPagoIgtf())).toFixed(2)}}</span>
                                                    </td>

                                                </tr>
                                                <tr v-if="((valorMaximo != -1 && valorMaximo !== undefined)  && funciones.monedaPrincipal().id != monedaSelect.id )">
                                                    <td>
                                                        <strong class="primary--text">Tasa: {{ monedaSelect.indMultiplicar ? (1 / monedaSelect.tasa).toFixed(2) : monedaSelect.tasa }}</strong>
                                                    </td>
                                                    <td class="text-right">

                                                        <span  :class="(valorMaximo - TotalPago() + TotalPagoIgtf()).toFixed(2) > 0 ? 'error--text':'success--text'"
                                                            v-if="(valorMaximo - TotalPago() + TotalPagoIgtf()).toFixed(2) >= 0">
                                                            
                                                            {{monedaSelect.simbolo}} 
                                                            {{( (valorMaximo - TotalPago() +
                                                            TotalPagoIgtf()) * monedaSelect.tasa).toFixed(2)}}</span>


                                                        <span class="success--text" v-else>
                                                            {{monedaSelect.simbolo}} {{ (((-1 * (valorMaximo - TotalPago() +
                                                        TotalPagoIgtf()))) * monedaSelect.tasa).toFixed(2)}}</span>
                                                    </td>

                                                </tr>
                                                

                                            </tbody>
                                        </template>

                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-list width="100%" style="max-height: 480px; overflow-y: auto;">
                                <h4 class="text-center" v-if="pagos.length == 0">No hay pagos registrados</h4>
                                <template v-for="(item, index) in pagos">
                                    <v-list-item :key="index + 'pagos'">
                                        <v-list-item-avatar size="30">
                                            {{ item.moneda.simbolo }}
                                        </v-list-item-avatar>

                                        <v-list-item-content class="p-0 m-0">
                                            <v-list-item-subtitle v-if="indFecha" class="  py-0 my-0">
                                                <strong><small>{{ item.fecha }}</small></strong>
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle class="  py-0 my-0">{{ item.descripcion }}
                                            </v-list-item-subtitle>

                                            <v-list-item-subtitle class="  py-0 my-0">{{
                                                item.moneda.formaPago.find(i => (i.id
                                                    == item.formaPago)).descripcion
                                            }} {{ item.moneda.simbolo }}:
                                                {{
                                                    Number(item.montoPagoMoneda).toFixed(2)
                                                }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle class="py-0 my-0" v-if="item.moneda.tasa != 1">
                                                <small>Monto {{ funciones.monedaPrincipal().simbolo }}:
                                                    {{
                                                        Number(item.montoPagoReal).toFixed(2)
                                                    }}/ tasa: {{  item.moneda.indMultiplicar ?  (1 / item.moneda.tasa).toFixed(2) : item.moneda.tasa.toFixed(2)  }}</small>
                                            </v-list-item-subtitle>

                                            <v-list-item-subtitle class="py-0 my-0" v-if="item.referencia != ''"><small>
                                                    Ref: {{ item.referencia }}</small>
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle class="py-0 my-0 success--text"
                                                v-if="item.vueltoInfo.montoPagoMoneda > 0">
                                                <small>Vuelto {{ item.vueltoInfo.moneda.simbolo }}:
                                                    {{ item.vueltoInfo.montoPagoMoneda.toFixed(2) }}</small>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn icon>
                                                <v-icon @click="EliminarPago(item)" color="grey lighten-1">{{
                                                    mdiCloseCircle
                                                }}
                                                </v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-divider :key="index"></v-divider>
                                </template>
                            </v-list>
                        </v-col>
                    </v-row>
                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary"   @click="dialogModal = false">
                        Cerrar
                    </v-btn>
                    <v-btn color="primary"   @click="guardarPagos()">
                        Aceptar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>
<script>
import { ref, onBeforeMount, onBeforeUpdate, watch } from '@vue/composition-api'
import { mdiCash, mdiCloseCircle, mdiKeyboardF5 } from '@mdi/js'
import store from '@/store'
import funciones from '@/funciones/funciones'
export default {
    props: {
        monedas: Array,
        pagosInt: Array,
        dialog: Boolean,
        indFecha: Boolean,
        valorMaximo: Number,
        indVuelto: Boolean,
        disabled: Boolean,
        btnVisible:Boolean
    },
    setup(props, context) {
        const dialogModal = ref(false)
        const monedaPais = ref({})
        const modal = ref(false)
        const configuracion = { igtf: 0 }
        const alertas = (sa) => {
            //alert(sa)
        }
        const comandaPagoINIT = {
            id: 0,
            moneda: { id: '', simbolo: '', tasa: 1 },
            formaPago: '',
            montoPagoMoneda: '',
            monto: 0,
            referencia: '',
            montoPagoReal: '',
            nuevo: true,
            fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            igtf: 0,
            vueltoInfo: {
                moneda: { simbolo: '' },
                montoPagoMoneda: '',
                monto: '',
                formaPago: '',
                mostrar: false,
            },

        }
        function round(num) {
            var m = Number((Math.abs(num) * 100).toPrecision(15))
            return (Math.round(m) / 100) * Math.sign(num)
        }


        const monedaSelect = ref(null)
        const formasPagos = ref([])
        const formaPagoSelect = ref()
        const montoDolar = ref()
        const montoPagoMoneda = ref()
        const montoDolarRef = ref()
        const montoMonedaRef = ref(null)
        const pago = ref(JSON.parse(JSON.stringify(comandaPagoINIT))   )
        const montoPagoReal = ref('')
        const pagos = ref([])
        const contador = ref(-5000)

        const abrirModal = () => {
            dialogModal.value = true
        }

        const monedasFilter = () => {
            return props.monedas.filter(item => (item.id != -1))
        }
        let contadorPago = -10000
        const idDefaultPago = () => {
            contadorPago = contadorPago + 1
            return contadorPago
        }
        watch(dialogModal, () => { 
            
            limpiar()
            MonedaSelect(props.monedas[0]) 
            pago.value.moneda  = props.monedas[0]
           
            monedaPais.value = funciones.monedaPais()

        })

        watch(props, () => {
            monedaPais.value = funciones.monedaPais()
            limpiar() 

        })

        onBeforeMount(() => {
            limpiar()
            MonedaSelect(props.monedas[0])
            monedaPais.value = funciones.monedaPais()
            montoPagoReal.value = (props.valorMaximo- TotalPago() + TotalPagoIgtf()).toFixed(2)
           
        })

        const limpiar = () => {
            pagos.value = props.pagosInt ? JSON.parse(JSON.stringify(props.pagosInt)) : []
            montoPagoMoneda.value = ""
            montoPagoReal.value = ""
         
            let min = -5000
            if (pagos.value.length > 0) {
                pagos.value.forEach(item => {
                    if (item.id > min) {
                        min = item.id
                    }
                })
            }
            contador.value = min
        }

        const constadorNew = () => {
            contador.value += 1
            return contador.value
        }

        const tasaIgualDolar = () => {
            if (!pago.value.moneda.tasa) {
                return true
            } else {
                if (pago.value.moneda.tasa == 1) {
                    return true
                } else {
                    return false
                }
            }
        }


        const guardarPagos = () => {
            ////alert(TotalPagoIgtf())
            ////alert("valorMaximo"+ round (props.valorMaximo) + 'TotalPago' +TotalPago() +'TotalPagoIgtf'+ TotalPagoIgtf())
            if (( round (props.valorMaximo)- TotalPago() + TotalPagoIgtf()) <= 0) {
                context.emit('GetPagos', pagos.value)
                dialogModal.value = false
            } else {
                store.commit('setAlert', {
                    message: 'Debe completar todos los pagos',
                    type: 'error',
                })
            }

        }

        const calcularMontoDolar = valor => {
          
            if (valor == 1) {
                pago.value.montoPagoReal = (Number(pago.value.montoPagoMoneda) / Number(pago.value.moneda.tasa)).toFixed(2)
                montoPagoReal.value = (Number(pago.value.montoPagoMoneda) / Number(pago.value.moneda.tasa))
            } else {
                pago.value.montoPagoMoneda = (Number(pago.value.montoPagoReal) * Number(pago.value.moneda.tasa)).toFixed(2)
                montoPagoMoneda.value = (Number(pago.value.montoPagoReal) * Number(pago.value.moneda.tasa))
            }
        }

        const MonedaSelect = item => {
            pago.value.montoPagoMoneda = ''
            pago.value.montoPagoReal = ''
            pago.value.referencia = ''
            monedaSelect.value = { ...item }
            pago.value.formaPago = item.formaPago[0].id
            montoMonedaRef.value.focus()
            montoPagoReal.value = (props.valorMaximo- TotalPago() + TotalPagoIgtf()).toFixed(2)
        }

        const TotalPago = () => {
            let total = 0

            pagos.value.forEach(element => {
                total += Number(element.montoPagoReal)
            });

            return total
        }
        const TotalPagoIgtf = () => {
            let total = 0
            pagos.value.forEach(element => {
                total += Number(element.igtf)
            });
            
            return total
        }
        const totalIgtf = ref(0)

        const GetPagos = () => {

            let igtf = 0
            let error = 0
            let vueltoInfo = {}
            //calculo igtf

            if (monedaPais.value.id != monedaSelect.value.id) {
                if ((props.valorMaximo - TotalPago()) >= Number(montoPagoReal.value)) {
                    igtf = round(Number(montoPagoReal.value) * (configuracion.igtf / 100))

                } else {
                    if ((props.valorMaximo + TotalPagoIgtf() - TotalPago()) > 0) {
                        igtf = round(((props.valorMaximo - TotalPago())) * (configuracion.igtf / 100))
                    } else {
                        store.commit('setAlert', {
                            message: 'Ya completo el monto de la factura',
                            type: 'info',
                        })

                        error = 1
                    }

                    const vuelto = montoPagoReal.value - (igtf + ((props.valorMaximo + TotalPagoIgtf()) - (TotalPago())))
                    vueltoInfo = {
                        moneda: monedaSelect.value,
                        montoPagoReal: round(vuelto),
                        montoPagoMoneda: round(vuelto * monedaSelect.value.tasa),
                        mostrar: 1,
                        formaPago: '',
                    }
                }
            } else {
                if ((props.valorMaximo + TotalPagoIgtf() - TotalPago()) <= 0) {
                    store.commit('setAlert', {
                        message: 'Ya completo el monto de la factura',
                        type: 'info',
                    })
                    error = 1
                } else {
                    const vuelto = montoPagoReal.value - (igtf + (props.valorMaximo + TotalPagoIgtf() - TotalPago()))
                    vueltoInfo = {
                        moneda: monedaSelect.value,
                        montoPagoReal: round(vuelto),
                        montoPagoMoneda: round(vuelto * monedaSelect.value.tasa),
                        mostrar: 1,
                        formaPago: '',
                    }
                }
            }
            if (error == 0) {
                pagos.value = [
                    ...pagos.value,
                    { ...pago.value, 
                        id: pago.value.id > 0 ? pago.value.id : idDefaultPago(), 
                        igtf, 
                        vueltoInfo ,
                        idFormaPago : pago.value.formaPago , 
                        montoPagoPais : round(pago.value.montoPagoReal * monedaPais.value.tasa),
                        tasa:monedaSelect.value.tasa
                    }
                        
                ]
                pago.value  = JSON.parse(JSON.stringify(comandaPagoINIT))   
                montoPagoReal.value = ''
                montoPagoMoneda.value = ''
                montoMonedaRef.value.focus()
            }
        }

        const AgregarPago = () => {
            if ((pago.value.montoPagoMoneda >= 0.00001) && (pago.value.formaPago > 0)) {
                if (props.valorMaximo == -1 || props.valorMaximo == undefined) {
                    /*pagos.value.push({ ...pago.value, id: constadorNew() })
                    pago.value = { ...comandaPagoINIT }
                    console.log(pagos.value)*/
                    GetPagos()
                } else {

                    GetPagos()

                }
            } else {
                if ((pago.value.montoPagoMoneda >= 0.00001) && (pago.value.formaPago > 0)) {
                    store.commit('setAlert', {
                        message: 'Debe seleccionar un tipo de pago valido',
                        type: 'error',
                    })
                }
                else {

                    store.commit('setAlert', {
                        message: 'Debe insertar un valor valido',
                        type: 'error',
                    })
                }
            }
        }


        const EliminarPago = item => {
            pagos.value = pagos.value.filter(it => (it.id != item.id))

        }

        const descripcionTasa = () => {
            if (monedaSelect.value.id != monedaPais.value.id) {
                let total = 0
                if ((props.valorMaximo > TotalPago())) {
                    total = round((props.valorMaximo - (TotalPago())) * (1 + (configuracion.igtf / 100))) + (round(TotalPagoIgtf()))

                } else {
                    if ((props.valorMaximo - TotalPago()) * (1 + (configuracion.igtf / 100)) > 0) {
                        total = (props.valorMaximo - TotalPago()) * (1 + (configuracion.igtf / 100))
                    } else {
                        total = 0
                    }
                }
                 if (configuracion.igtf > 0) {
                    return `Total ${monedaSelect.value.simbolo}: ${round((round(total)*monedaSelect.value.tasa))} ${(monedaSelect.value.tasa != 1 ? ('/ tasa: ' + monedaSelect.value.indMultiplicar)?  (1/monedaSelect.value.tasa).toFixed(2): monedaSelect.value.tasa.toFixed(2) : '')}`

                 }else{
                    return `Total  ${monedaSelect.value.simbolo}: ${round((round(total)*monedaSelect.value.tasa))} ${(monedaSelect.value.tasa != 1 ? ('/ tasa: ' + monedaSelect.value.indMultiplicar)?  (1/monedaSelect.value.tasa).toFixed(2): monedaSelect.value.tasa.toFixed(2) : '')}`

                 }
                //calculo igtf
               

            } else {
                if ((props.valorMaximo > TotalPago())) {
                  return `Resta ${monedaSelect.value.simbolo}: ${((props.valorMaximo - TotalPago() + TotalPagoIgtf()) * monedaSelect.value.tasa).toFixed(2)} ${monedaSelect.value.tasa == 1 ? '':  monedaSelect.value.indMultiplicar == true ?  ('/ Tasa: '+ monedaSelect.value.simbolo +' '+(1/monedaSelect.value.tasa).toFixed(2) ): ('/ Tasa:' + monedaSelect.value.simbolo+' '+ monedaSelect.value.tasa.toFixed(2) ) }`
                }else{
                    return 'Tasa: ' + (monedaSelect.value.indMultiplicar?  (1/monedaSelect.value.tasa).toFixed(2): monedaSelect.value.tasa.toFixed(2) )
              
                }
            }

        }
        watch(montoPagoMoneda, () => {
            pago.value.montoPagoMoneda = montoPagoMoneda.value
            calcularMontoDolar(1)
        });

        watch(montoPagoReal, () => {
            pago.value.montoPagoReal = montoPagoReal.value
            calcularMontoDolar(2)

        });

        watch(monedaSelect, () => {
            formaPagoSelect.value = null
            formasPagos.value = monedaSelect.value.formaPago
        })

        const SelectFormaPago = item => {
            pago.value.formaPago = item.id
            montoMonedaRef.value.focus()
        }

        return {
            icons: {
                mdiKeyboardF5
            },
            monedaSelect,
            mdiCash,
            mdiCloseCircle,
            formasPagos,
            SelectFormaPago,
            formaPagoSelect,
            montoDolar, 
            descripcionTasa,
            tasaIgualDolar,
            montoDolarRef,
            montoMonedaRef,
            pagos,
            pago,
            calcularMontoDolar,
            MonedaSelect,
            rules: {
                required: value => !!value || 'Required.',
                counter: value => value.length >= 0,
            },
            AgregarPago,
            EliminarPago,
            monedaPais,
            monedasFilter,
            guardarPagos,
            limpiar,
            modal,
            TotalPago,
            montoPagoMoneda,
            montoPagoReal,
            dialogModal,
            totalIgtf,
            TotalPagoIgtf,
            abrirModal,
            alertas,
            funciones,
            configuracion
        }
    },
}
</script>