<template>
  <div>
    <v-text-field
      :disabled="cargando"
      :loading="cargando"
      ref="inputText"
      v-model="dato"
      :prepend-inner-icon="icons.mdiBarcode"
      hide-details="true"
      dense
      outlined
      label="Codigo Barra"
      :append-outer-icon="icons.mdiSearchWeb"
      @keydown.enter="buscar()"
    ></v-text-field>

    <v-dialog v-model="dialog" scrollable max-width="500px">
      <v-card>
        <v-card-title>Seleccional el lote</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-simple-table dense>
            <template v-slot:default> 
                <thead>
                    <tr>
                        <th>-</th>
                        <th>Descripcion</th>
                        <th>Fecha Vencimiento</th>
                    </tr>
                </thead>
              <tbody>
                <tr v-for="(item, index) in datoResult.lote" :key="index" @click="SeleccionarLote(item.id)">
                  <td>
                    <v-checkbox  readonly  v-model="selLote" :value="item.id"></v-checkbox>
                  </td>
                  <td>{{ item.numeroLote }}</td>
                  <td>{{ FuncionesGenerales.formatoFecha(item.fechaVencimiento) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
<!--
          <v-radio-group v-model="selLote" column ref="radio">
            <v-radio
              v-for="(item, index) in datoResult.lote"
              :key="index"
              :label="`${item.numeroLote} V:${FuncionesGenerales.formatoFecha(item.fechaVencimiento)} Exis:${
                item.existenciaLote.inventario
              }`"
              :value="item.id"
            ></v-radio>
          </v-radio-group>-->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="Limpiar()"> Limpiar </v-btn>
          <v-btn color="primary" @click="selectLote()"> Seleccionar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import { mdiSearchWeb, mdiBarcode } from '@mdi/js'
import ProductoSucursalServices from '@/api/servicios/ProductoSucursalServices'
import store from '@/store'
import FuncionesGenerales from '@/funciones/funciones'
export default {
  props: {
    idArea: {
      type: Number,
      default: -1,
    },
    indInventario: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const dialog = ref(false)
    const dato = ref('')
    const inputText = ref(null)
    const radio = ref(null)
    const datoResult = ref({
      lote: [],
    })
    const selLote = ref(null)
    const focus1 = () => {
      inputText.value.focus()
    }
    const selectLote = () => {
      if (selLote.value != null) {
        dialog.value = false
        var lot = datoResult.value.lote.find(i => i.id == selLote.value)

        datoResult.value.lote = []
        datoResult.value.lote.push(lot)
        console.log(datoResult.value)
        retornar()
      } else {
        store.commit('setAlert', {
          message: 'Seleccione un lote',
          type: 'warning',
        })
      }
    }
    const Limpiar = () => {
      dato.value = ''
      dialog.value = false
      datoResult.value = {
        lote: [],
      }
      inputText.value.focus()
    }
    const cargando = ref(false)
    const buscar = () => {
      if (dato.value != '') {
        cargando.value = true
        try {
          ProductoSucursalServices.ConsultarBarra(
            dato.value,
            store.state.sucursalSelect.id,
            props.idArea,
            props.indInventario,
          )
            .then(response => {
              if (response.data.estatus) {
                datoResult.value = response.data.datos
                if (datoResult.value != null) {
                  if (datoResult.value.producto.indLote == true) {
                    if (datoResult.value.lote.length > 1) {
                      dialog.value = true
                      selLote.value = datoResult.value.lote[0].id
                    } else {
                      retornar()
                    }
                  } else {
                    retornar()
                  }
                } else {
                  store.commit('setAlert', {
                    message: 'Error al buscar el producto',
                    type: 'error',
                  })
                }
              } else {
                Limpiar()
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'warning',
                })
              }
            })
            .catch(err => {
              store.commit('setAlert', {
                message: err,
                type: 'error',
              })
            })
            .finally(() => {
              cargando.value = false
            })
        } catch (error) {
          cargando.value = false
          store.commit('setAlert', {
            message: error,
            type: 'error',
          })
        }
      }
    }
    const SeleccionarLote = (item)=>{
      
        selLote.value = item
    }
    const retornar = () => {
      context.emit('input', datoResult.value)
      inputText.value.focus()
      Limpiar()
    }
    return {
      icons: {
        mdiSearchWeb,
        mdiBarcode,
      },
      inputText,
      focus1,
      buscar,
      dato,
      cargando,
      dialog,
      FuncionesGenerales,
      selLote,
      selectLote,
      datoResult,
      Limpiar,
      radio,
      SeleccionarLote
    }
  },
}
</script>