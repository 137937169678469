<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <div class="d-flex align-center">
           
            <div class="d-flex flex-column ms-3">
              <span class="d-block font-weight-semibold text--primary  " style="width: 500px"> 
                  {{ FuncionesGenerales.camelCase(datos.producto.producto.nombre) }} 
                  <br>
                  <small>{{datos.producto.producto.barra  }}</small>
              </span> 
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  hide-details="true"
                  autofocus
                  label="Cantidad"
                  outlined
                  v-model="numeroUnidadesText"
                  required
                  @keydown.enter="modificar()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field hide-details="true" label="PVP" outlined v-model="pvpText" readonly></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-simple-table fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Cantidad</th>
                        <th class="text-left">Precio</th>
                        <th class="text-left">SubTotal</th>
                        <th class="text-left">Descuento</th>
                        <th class="text-left">Iva</th>
                        <th class="text-left">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ datos.numeroUnidades }}</td>
                        <td>
                          <span class="d-block font-weight-semibold text--primary text-truncate">
                            {{ datos.pvp.toFixed(2) }}
                          </span>
                          <small class="primary--text"> {{ datos.pvpPais.toFixed(2) }} </small>
                        </td>
                        <td>
                          <span class="d-block font-weight-semibold text--primary text-truncate">
                            {{ datos.subTotal.toFixed(2) }}
                          </span>
                          <small class="primary--text">{{ datos.subTotalPais.toFixed(2) }}</small>
                        </td>
                        <td>
                          <span class="d-block font-weight-semibold text--primary text-truncate">
                            {{ datos.descuento.toFixed(2) }}
                          </span>
                          <small class="primary--text"> {{ datos.descuentoPais.toFixed(2) }} </small>
                        </td>
                        <td>
                          <span class="d-block font-weight-semibold text--primary text-truncate">
                            {{ datos.iva.toFixed(2) }}
                          </span>
                          <small class="primary--text"> {{ datos.ivaPais.toFixed(2) }} </small>
                        </td>
                        <td>
                          <span class="d-block font-weight-semibold text--primary text-truncate">
                            {{ datos.total.toFixed(2) }}
                          </span>
                          <small class="primary--text"> {{ datos.totalPais.toFixed(2) }} </small>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secundary" @click="dialog = false"> Cancelar </v-btn>
          <v-btn color="error" @click="eliminar()"> Borrar </v-btn>
          <v-btn color="primary" @click="modificar()"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import FuncionesGenerales from '@/funciones/funciones'
import store from '@/store'
export default {
  setup(props, context) {
    const dialog = ref(false)
    const datos = ref({})
    const pvpText = ref(0)
    const numeroUnidadesText = ref(0)

    const abrir = dato => {
      console.log('ModalEditarProducto', dato)
      dialog.value = true
      datos.value = dato
      numeroUnidadesText.value = dato.numeroUnidades
      pvpText.value = dato.pvp
    }
    function round(num) {
      var m = Number((Math.abs(num) * 100).toPrecision(15))
      return (Math.round(m) / 100) * Math.sign(num)
    }

    const modificar = () => {
      let _existencia = 0
      console.log('datos.value.producto.lote', datos.value.producto.lote)
      if (datos.value.numeroUnidades > 0) {
        if (datos.value.producto.lote != null && datos.value.producto.lote.length > 0) {
          _existencia = datos.value.producto.lote[0].existenciaLote.inventario
        } else {
          _existencia = datos.value.producto.existencia.inventario
        }

        if (datos.value.numeroUnidades <= _existencia) {
          console.log('Existencia', datos.value)
          context.emit('modificar', datos.value)
          dialog.value = false
        } else {
          store.commit('setAlert', {
            message: `La existencia actual es de ${_existencia}`,
            type: 'warning',
          })
        }
      } else {
        store.commit('setAlert', {
          message: `La cantidad debe ser mayor que 0`,
          type: 'warning',
        })
      }
    }
    const eliminar = () => {
      context.emit('eliminar', datos.value)
      dialog.value = false
    }
    watch(pvpText, () => {
      calcular()
    })
    watch(numeroUnidadesText, () => {
      calcular()
    })
    const calcular = () => {
      let tasaImpuestoValor = datos.value.tasaImpuestoValor /*decimal*/
      let numeroUnidades =
        !isNaN(numeroUnidadesText.value) &&
        numeroUnidadesText.value != null &&
        numeroUnidadesText.value != undefined &&
        numeroUnidadesText.value != ''
          ? numeroUnidadesText.value
          : 0
      let pvpPais = round(round(datos.value.pvp) * FuncionesGenerales.monedaPais().tasa) /*decimal*/
      let pvp = round(pvpText.value) /*decimal*/
      let descuento = round(0) /*decimal*/
      let descuentoPais = round(0) /*decimal*/
      let subTotal = round(numeroUnidades * pvp)
      let subTotalPais = round(numeroUnidades * pvpPais)
      let iva = round((subTotal - descuentoPais) * round(tasaImpuestoValor / 100)) /*decimal*/
      let ivaPais = round((subTotalPais - descuento) * round(tasaImpuestoValor / 100)) /*decimal*/
      let totalPais = round(subTotalPais + ivaPais - descuentoPais) /*decimal*/
      let total = round(subTotal + iva - descuento) /*decimal*/
      datos.value = {
        ...datos.value,
        tasaImpuestoValor,
        numeroUnidades,
        pvpPais,
        pvp,
        descuento,
        descuentoPais,
        subTotal,
        subTotalPais,
        iva,
        ivaPais,
        totalPais,
        total,
      }
    }

    return {
      dialog,
      abrir,
      datos,
      FuncionesGenerales,
      numeroUnidadesText,
      pvpText,
      modificar,
      eliminar,
    }
  },
}
</script>