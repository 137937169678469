<template>
    <v-row class="text-center">
        <v-dialog v-model="dialog" width="600" scrollable>
            <v-card>
                <v-card-title>
                    <div class="d-flex align-center">
                        Buscar Pedido
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-col>
                            <v-text-field hide-details="true" autofocus label="Número de Pedido" outlined
                                 v-model="numberDocument" required @keydown.enter="buscar()">
                            </v-text-field>
                        </v-col>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secundary" @click="cerrarDialog()">
                        Cancelar
                    </v-btn>
                    <v-btn color="primary" @click="buscar()">
                        Aceptar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { ref } from '@vue/composition-api'
import PedidoServices from '@/api/servicios/PedidoServices'
import store from '@/store'
export default {
    setup(props, context){
        const dialog = ref(false)
        const dato = ref({})
        const cargando = ref(false)
        const numberDocument =  ref(null)
        const abrirModal = () => {
            dialog.value = true;
        }
        const buscar = () => {
            cargando.value = true
            dato.value = {};
            PedidoServices.ConsultarDetalle({numeroDocumento: numberDocument.value, idSucursal: store.state.sucursalSelect.id})
            .then(response => {
                if(response.data.estatus == true){
                    if (response.data.datos != undefined) {
                        switch (response.data.datos[0].idEstatus) {
                            case 22:
                                dato.value = response.data.datos[0];
                                context.emit('obtenerPedido', dato);
                                numberDocument.value = "";
                                dialog.value = false;
                                break;
                            case 23:
                                dato.value = response.data.datos[0];
                                context.emit('obtenerPedido', dato);
                                numberDocument.value = "";
                                dialog.value = false;
                                break;
                            case 24:
                                    store.commit('setAlert', {
                                    message: 'Este pedido fue facturado',
                                    type: 'warning',
                                })
                                break;
                            case 25:
                                    store.commit('setAlert', {
                                    message: 'Este pedido fue anulado',
                                    type: 'warning',
                                })
                                break;
                            case 26:
                                    store.commit('setAlert', {
                                    message: 'Este pedido se encuentra vencido',
                                    type: 'warning',
                                })
                                break;
                            case 26:
                                    dato.value = response.data.datos[0];
                                    context.emit('obtenerPedido', dato);
                                    numberDocument.value = "";
                                    dialog.value = false;
                                    store.commit('setAlert', {
                                    message: 'El pago de este pedido ah sido rechazado',
                                    type: 'warning',
                                })
                                break;
                            default:
                                break;
                        }
                        } else {
                        store.commit('setAlert', {
                            message: 'No se obtuvo resultado relacionado a la busqueda',
                            type: 'warning',
                        })
                    }
                }else{
                    store.commit('setAlert',{
                        menssage: 'No hubo respuesta',
                        type: 'warning'
                    })
                }
            })
            .catch(err => {
                store.commit('setAlert', {
                    message: err,
                    type: 'error',
                })
            })
            .finally(() => {
                cargando.value = false
        })
        }
        const cerrarDialog = () => {
            dialog.value = false;
            numberDocument.value = "";
        }
        return {
            abrirModal,
            buscar,
            cerrarDialog,
            dialog,
            dato,
            cargando,
            numberDocument
        }
    }
}

</script>