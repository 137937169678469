<template>
  <v-row>
    <v-col cols="12" class="pt-6">
      <v-simple-table dense>
        <template>
          <tbody>
            <tr>
              <td class="text-left">
                <h3>Total:</h3>
              </td>
              <td class="text-right" v-if="visualizarMoneda.includes('PRINCIPAL')">
                <h3>{{ monedaPrincipal.simbolo }}.{{ (factura.montoTotal + factura.montoIgtf).toFixed(2) }}</h3>
              </td>
              <td class="text-right" v-if="visualizarMoneda.includes('PAIS')">
                <h3>{{ monedaPais.simbolo }}.{{ (factura.montoTotalPais + factura.montoIgtfPais).toFixed(2) }}</h3>
              </td>
            </tr>

            <tr>
              <td class="text-left">
                <h5>Pagos:</h5>
              </td>
              <td class="text-right" v-if="visualizarMoneda.includes('PRINCIPAL')">
                <h5>{{ monedaPrincipal.simbolo }}.{{ factura.montoPagado.toFixed(2) }}</h5>
              </td>
              <td class="text-right" v-if="visualizarMoneda.includes('PAIS')">
                <h5>{{ monedaPais.simbolo }}.{{ factura.montoPagadoPais.toFixed(2) }}</h5>
              </td>
            </tr>

            <tr>
              <td class="text-left">
                <h5 v-if="factura.resta >= 0">Resta:</h5>
                <h5 v-else>Vuelto</h5>
              </td>
              <td class="text-right" v-if="visualizarMoneda.includes('PRINCIPAL')">
                <h5>
                  <span :class=" factura.resta.toFixed(2) <= 0 ? 'success--text' : 'error--text'"
                    >{{ monedaPrincipal.simbolo }}.{{
                      factura.resta >= 0 ? factura.resta.toFixed(2) : (factura.resta * -1).toFixed(2)
                    }}</span
                  >
                </h5>
              </td>
              <td class="text-right" v-if="visualizarMoneda.includes('PAIS')">
                <h5 class="success--text">
                  {{ monedaPais.simbolo }}.{{
                    factura.restaPais >= 0 ? factura.restaPais.toFixed(2) : (factura.restaPais * -1).toFixed(2)
                  }}
                </h5>
              </td>
            </tr>
            <tr v-if="false">
              <td class="text-left">
                <small>Dif</small>
              </td>
              <td class="text-right" v-if="visualizarMoneda.includes('PRINCIPAL')">
                <small>{{ monedaPrincipal.simbolo }}.{{ funciones.roundToTwo(factura.diferencia, 4) }}</small>
              </td>
              <td class="text-right" v-if="visualizarMoneda.includes('PAIS')">
                <small>{{ monedaPais.simbolo }}.{{ funciones.roundToTwo(factura.diferenciaMonedaPais, 4) }}</small>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import funciones from '@/funciones/funciones'
export default {
  props: {
    factura: Object,
    visualizarMoneda: Array,
  },
  setup() {
    const monedaPais = ref({})
    const monedaPrincipal = ref({})
    onBeforeMount(() => {
      monedaPais.value = funciones.monedaPais()
      monedaPrincipal.value = funciones.monedaPrincipal()
    })
    return {
      monedaPais,
      monedaPrincipal,
      funciones,
    }
  },
}
</script>