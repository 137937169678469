<template>
    <v-card>
        <v-card-subtitle>Direccion</v-card-subtitle>
        <v-card-text>
            <v-form ref="form" lazy-validation>
                <v-row>
                    <v-col sm="6" md="4">
                        <SelectTipoDireccion :rules="reglas.requerido" v-model="tipoDireccion" :dense="true"
                            :outlined="true">
                        </SelectTipoDireccion>
                       
                    </v-col>
                    <v-col sm="6" md="4">
                        <SelectPais :rules="reglas.requerido" v-model="pais" :dense="true" :outlined="true">
                        </SelectPais>
                        
                    </v-col>

                    <v-col sm="6" md="4">
                        <SelectEstado :rules="reglas.requerido" v-model="estado" :dense="true" :outlined="true"
                            :padre="pais.id">
                        </SelectEstado>
                     
                    </v-col>
                    <v-col sm="6" md="4">
                        <SelectMunicipio :rules="reglas.requerido" v-model="municipio" :dense="true" :outlined="true"
                            :padre="estado.id">
                        </SelectMunicipio>
                       
                    </v-col>
                    <v-col sm="6" md="4">
                        <SelectCiudad :rules="reglas.requerido" v-model="ciudad" :dense="true" :outlined="true"
                            :padre="municipio.id">
                        </SelectCiudad>
                      
                    </v-col>

                    <v-col sm="6" md="4">
                        <v-text-field v-model="dato.avenida" hide-details dense outlined label="Avenida"></v-text-field>
                    </v-col>
                    <v-col sm="6" md="3">
                        <v-text-field v-model="dato.calle" hide-details dense outlined label="Calle"></v-text-field>
                    </v-col>

                    <v-col sm="6" md="3">
                        <v-text-field v-model="dato.edificio" hide-details dense outlined
                            label="Edificio"></v-text-field>
                    </v-col>

                    <v-col sm="6" md="3">
                        <v-text-field v-model="dato.piso" hide-details dense outlined label="Piso"></v-text-field>
                    </v-col>

                    <v-col sm="6" md="3">
                        <v-text-field v-model="dato.apartamento" hide-details dense outlined
                            label="Apartamento"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="dato.direccionExtendida" label="Direccion extendida" dense outlined
                            hide-details></v-textarea>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="text-right">
            <v-btn small color="secondary" class="mr-1" @click="limpiar()">Limpiar</v-btn>
            <v-btn small color="primary" @click="Guardar()">Guardar</v-btn>

        </v-card-text>
    </v-card>
</template>
<script>
import SelectPais from '@/views/sistema/maestro/pais/componentes/SelectJson.vue';
import SelectEstado from '@/views/sistema/maestro/estado/componentes/SelectJson.vue'
import SelectMunicipio from '../../municipio/componentes/SelectJson.vue';
import SelectCiudad from '../../ciudad/componentes/SelectJson.vue'
import SelectTipoDireccion from '../../tipo-direccion/componentes/SelectJson.vue';
import { onBeforeMount, ref, watch } from '@vue/composition-api';
import store from '@/store';
export default {
    props: {

    },
    components: {
        SelectPais,
        SelectEstado,
        SelectMunicipio,
        SelectCiudad,
        SelectTipoDireccion
    },
    watch: {
        pais: function (newVal, oldVal) {
            if (this.modificando != true) {
                if (newVal != oldVal) {
                    this.estado = { id: null } 
                }
            }
        },
        estado: function (newVal, oldVal) {
            if (this.modificando != true) {
                if (newVal != oldVal) {
                    this.municipio = { id: null } 
                }
            }
        },
        municipio: function (newVal, oldVal) {
            
            if (this.modificando != true) {
                if (newVal != oldVal) {
                    this.ciudad = { id: null }
                }
            } else {
                if (newVal.id == oldVal.id && this.municipio.id != null) {
                  
                    this.modificando = false
                }
            }

        },

    },
    setup(props, contex) {
        const form = ref(null)

        const tipoDireccion = ref({ id: 3 })
        const pais = ref({ id: 117 })
        const estado = ref({ id: null })
        const municipio = ref({ id: null })
        const ciudad = ref({ id: null })
        
        const modificando = ref(false)
        const modificar = (DatosIn) => { 
            if (DatosIn.id !== undefined) {   
                modificando.value = true
                dato.value = DatosIn
                tipoDireccion.value = { id: DatosIn.idTipoDireccion }
                pais.value = { id: DatosIn.idPais }
                estado.value = { id: DatosIn.idEstado }
                municipio.value = { id: DatosIn.idMunicipio }
                ciudad.value = { id: DatosIn.idCiudad }
            } else { 
                //alert("nuevo")
                modificando.value = false
                limpiar()
            }
        }


        const direccion = {
            id: -1/*long*/,
            idTipoDireccion: 3/*int*/,
            idPais: 117,
            idEstado: null,
            idMunicipio: null,
            idCiudad: null/*int*/,
            avenida: ""/*string*/,
            calle: ""/*string*/,
            edificio: ""/*string*/,
            piso: ""/*string*/,
            apartamento: ""/*string*/,
            direccionExtendida: ""/*string*/,
            codigoPostal: ""/*string*/,
            latitud: 0/*decimal*/,
            longitud: 0/*decimal*/,
        }
        const dato = ref({ ...direccion })
        const Guardar = () => {
            if (validarForm()) {
                contex.emit("GetDireccion", {
                    ...dato.value,
                    idTipoDireccion: tipoDireccion.value.id,
                    idPais: pais.value.id,
                    idEstado: estado.value.id,
                    idMunicipio: municipio.value.id,
                    idCiudad: ciudad.value.id,
                    tipoDireccion: tipoDireccion.value,
                    pais: pais.value,
                    estado: estado.value,
                    municipio: municipio.value,
                    ciudad: ciudad.value
                })
                limpiar()
            } else {
                store.commit('setAlert', {
                    message: 'Verifique que todos los datos estén completos',
                    type: 'warning',
                })
            }
        }
        const validarForm = () => {
            let val = form.value?.validate()
            return val
        }
        const reglas = {
            requerido: [
                v => !!v || "Es requerido",
            ],
        };

        const limpiar = () => {
           
            pais.value = { id: 117 } 
            tipoDireccion.value = { id: 3 } 
            dato.value = { ...direccion }
        }

        return {
            dato,
            Guardar,
            reglas,
            form,
            direccion,
            pais,
            estado,
            municipio,
            ciudad,
            tipoDireccion,
            limpiar,
            modificando,
            modificar
        }
    },
}
</script>