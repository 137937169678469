<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="900">
            <template v-if="BntVisible" v-slot:activator="{ on, attrs }">
                <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
                    Direccion
                </v-btn>
            </template>
            <Actualizar ref="ActualizarRef"   @GetDireccion="GetDireccion" />
        </v-dialog>
    </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api';
import Actualizar from './Actualizar.vue';
export default {
    components: {
        Actualizar
    },
    props: {
        BntVisible: Boolean
    },

    setup(props, contex) {
        const ActualizarRef = ref(null)
        const dialog = ref(false)
        const datoIn = ref(null)
        const Abrir = (dato) => {
            console.log(dato) 
            dialog.value = true
            //datoIn.value = dato 
            ActualizarRef.value.modificar(dato)
        }
        const GetDireccion = (dato) => {
           
            contex.emit("GetDireccion", dato)
            dialog.value = false
        }
        watch(dialog,()=>{
          
            if ( dialog.value == false) {
                
                ActualizarRef.value.limpiar()     
            }
        })
        return {
            Abrir,
            dialog,
            datoIn,
            GetDireccion,
            ActualizarRef
        }
    },
}
</script>