//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class FacturaServices {
    Consultar(
        datos = {
            Id: -1,
            IdCaja: -1,
            IdSucursal: '',
            IdArea: -1,
            IdEstatus: -1,
            IdCliente: -1,
            IdVendedor: -1,
            IdPedido: -1,
            NumeroFactura: -1,
            NumeroControl: -1,
            NumeroDocumento: '',
            Fecha: '',

        }
    ) {
        return http.get("Factura/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }
    ConsultarDetalle(
        datos  
    ) {
        return http.get("Factura/consultar/detalle", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }

    Actualizar(
        IdUsuario,
        Data
    ) {
        const datos = {
            IdUsuario,
            Data
        }
        console.log(datos)
        return http.post("/Factura/actualizar", datos)
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }



    Anular(
        IdUsuario,
        Data
      ) {
        const datos = {
          IdUsuario,
          Data
        }
        console.log(datos)
        return http.post("/factura/anular", datos)
          .catch((error) => {
            if (error.response.status == 401) {
              store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
              router.push("/pages/login");
            }
          });
      }
    
    VentaProductoSucursalReportePaginado(
        datos  
    ) {
        return http.get("Factura/consultar/venta/producto/reporte/paginado", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }
}


export default new FacturaServices();