<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="800">
            <template v-slot:activator="{ on, attrs }" v-if="btnVisible">
                <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
                    Clientes
                </v-btn>
            </template>

            <v-card>
                <v-card-subtitle class="text-h5 grey lighten-2">
                    Cliente
                </v-card-subtitle>

                <v-card-text>
                    <actualizar @GetActualizarLista="GetActualizarLista" :showDireccion="false"></actualizar>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="dialog = false">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import Actualizar from './Actualizar.vue';
export default {
    components: {
        Actualizar
    },
    props: {
        btnVisible: Boolean
    },
    setup(props,context) {
        const dialog = ref(false)
        const abrir = () => {
            dialog.value = true
        }
        const GetActualizarLista = (item) => { 
            dialog.value = false
            context.emit("GetCliente",item)
        }
        return {
            dialog,
            abrir,
            GetActualizarLista,
            
        }
    },
}
</script>