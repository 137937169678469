import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'


class PedidoServices {
    Actualizar(
        IdUsuario,
        Data
    ) {
        const datos = {
            IdUsuario,
            Data
        }
        console.log(datos)
        return http.post(`Pedido/actualizar`, datos)
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
                console.log(JSON.stringify(error));
            });
    }


    Consultar(
        datos
    ) {

        return http.get(`pedido/consultar`, { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
                console.log(JSON.stringify(error));
            });
    }


    ConsultarTipoPedido(
        datos
    ) {

        return http.get(`tipoPedido/consultar`, { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
                console.log(JSON.stringify(error));
            });
    }

    ConsultarDetalle(
        datos
    ) {

        return http.get(`Pedido/consultar/detalle`, { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
                console.log(JSON.stringify(error));
            });
    }
}


export default new PedidoServices();