<template>
    <v-dialog v-model="dialogLote" scrollable max-width="500px">
      <v-card>
        <v-card-title>Seleccionar el lote</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-simple-table dense>
            <template v-slot:default> 
                <thead>
                    <tr>
                        <th>-</th>
                        <th>Descripcion</th>
                        <th>Fecha Vencimiento</th>
                    </tr>
                </thead>
              <tbody>
                <tr v-for="(item, index) in datoResult.lote" :key="index" @click="SeleccionarLote(item.id)">
                  <td>
                    <v-checkbox  readonly v-model="selLote" :value="item.id"></v-checkbox>
                  </td>
                  <td>{{ item.numeroLote }}</td>
                  <td>{{ FuncionesGenerales.formatoFecha(item.fechaVencimiento) }}</td>
                </tr>
            
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="Limpiar()"> Limpiar </v-btn>
          <v-btn color="primary" @click="selectLote()"> Seleccionar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import FuncionesGenerales from '@/funciones/funciones';
import { ref } from '@vue/composition-api';
import ProductoSucursalServices from '@/api/servicios/ProductoSucursalServices';
import store from '@/store';
export default {
    props: {
        detallesProd: {
            type: Array,
            default: [],
        },
        idArea: {
        type: Number,
        default: -1,
        },
        indInventario: {
        type: Boolean,
        default: true,
        },
    },
    setup(props){
        const dialogLote = ref(false)
        const radio = ref(null)
        const index =  ref(null)
        const datoResult = ref({
            lote: [],
        })
        const selLote = ref(null);
        const cargando = ref(false)
        const listarLote = (info) => {
            dialogLote.value = true;
            index.value = info.index;
            datoResult.value.lote = info.dato.lotes;
            selLote.value = info.dato.idLote != null ? info.dato.idLote : null;
            console.log('SELLOTE', selLote.value)
            console.log('Lista Lotes', datoResult.value)
        }
        const selectLote = () => {
            if (selLote.value != null) {
                dialogLote.value = false
                var lot = datoResult.value.lote.find(i => i.id == selLote.value)
                props.detallesProd[index.value].idLote = selLote.value;
                props.detallesProd[index.value].producto.lote = [];     
                props.detallesProd[index.value].producto.lote.push(lot);
                console.log('OBJETO PRODUCTO CON DETALLE', props.detallesProd)
                retornar()
            } else {
                store.commit('setAlert', {
                message: 'Seleccione un lote',
                type: 'warning',
                })
            }
        }
        const SeleccionarLote = (item)=>{
            selLote.value = item
        }
        
        const retornar = () => {
            // context.emit('input', datoResult.value)
            Limpiar()
        }
        const Limpiar = () => {
            dialogLote.value = false;
            datoResult.value = {
                lote: [],
            }
        }
        return{
            cargando,
            dialogLote,
            FuncionesGenerales,
            selLote,
            datoResult,
            radio,
            index,
            Limpiar,
            retornar,
            // buscarLoteProducto,
            listarLote,
            selectLote,
            SeleccionarLote
        }
    },
}
// const buscarLoteProducto = (dato) => {
//     if (dato.value != '') {
//         cargando.value = true
//         try {
//         ProductoSucursalServices.ConsultarBarra(
//             dato,
//             store.state.sucursalSelect.id,
//             props.idArea,
//             props.indInventario,
//         )
//             .then(response => {
//             if (response.data.estatus) {
//                 datoResult.value = response.data.datos
//                 if (datoResult.value != null) {
//                     if (datoResult.value.producto.indLote == true) {
//                         if (datoResult.value.lote.length > 1) {
//                             dialogLote.value = true
//                             selLote.value = datoResult.value.lote[0].id
//                             console.log('LOTE',selLote.value)
//                         }else{
//                             store.commit('setAlert', {
//                             message: 'Este producto, solo tiene un lote.',
//                             type: 'warning',
//                             });
//                         }
//                     }else{
//                         store.commit('setAlert', {
//                         message: 'Error este producto no maneja lote.',
//                         type: 'error',
//                         });
//                     } 
//                 } else {
//                 store.commit('setAlert', {
//                     message: 'Error al buscar el producto',
//                     type: 'error',
//                 })
//                 }
//             } else {
//                 store.commit('setAlert', {
//                 message: response.data.mensaje,
//                 type: 'warning',
//                 })
//             }
//             })
//             .catch(err => {
//                 store.commit('setAlert', {
//                     message: err,
//                     type: 'error',
//                 })
//             })
//             .finally(() => {
//             cargando.value = false
//             })
//         } catch (error) {
//         cargando.value = false
//             store.commit('setAlert', {
//                 message: error,
//                 type: 'error',
//             })
//         }
//     }
// }
</script>