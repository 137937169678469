<template>
  <div>
    <v-row v-show="datosCaja.idCaja != undefined && store.state.sucursalSelect.id == datosCaja.caja.area.idSucursal">
      <v-col cols="12" sm="8" class="my-0 py-0">
        <div class="contenedor-productos">
          <lista-producto
            :visualizarMoneda="visualizarMoneda"
            @modificarDetalle="modificarDetalle"
            :detalles="factura.detalleFactura"
            key=""
            @eliminar="eliminarDetalle"
          ></lista-producto>
        </div>
        <div class="mt-2 contenedor-productos-buscador">
          <v-row class="my-0 py-0">
            <v-col cols="4" class="my-0 py-0">
              <buscardor-producto-barra
                v-model="producto"
                ref="BuscardorProductoBarraRef"
                class="my-1 py-0"
              ></buscardor-producto-barra>
            </v-col>
            <v-col cols="8" class="my-2 py-0 text-right">
              <v-btn color="primary" class="mr-2" x-small elevation="2" @click="abrirModalBusquedaProductoA()">
                <v-icon left dark class="mr-1">{{ icons.mdiMagnify }}</v-icon>
                Buscar Avanzada
                <v-icon right dark>
                  {{ icons.mdiKeyboardF1 }}
                </v-icon>
              </v-btn>
              <v-btn color="primary" class="mr-2" x-small elevation="2" @click="abrirModalBuscarPedido()"
                >Pedido
                <v-icon right dark>
                  {{ icons.mdiKeyboardF2 }}
                </v-icon>
              </v-btn>
              <v-btn color="primary" x-small elevation="2"
                >Recuperar
                <v-icon right dark>
                  {{ icons.mdiKeyboardF3 }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <div class="contenedor-productos-sub-totales">
          <v-card height="23vh">
            <sub-totales :visualizarMoneda="visualizarMoneda" :factura="factura"></sub-totales>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" sm="4" class="my-0 py-0">
        <div class="contenedor-productos-cliente">
          <factura-cliente @Getcliente="Getcliente" ref="FacturaClienteRef"></factura-cliente>
        </div>
        <div class="contenedor-productos-pago text-center">
          <v-card class="mt-2" height="calc(57vh - 115px)">
            <FacturaFormasPago
              ref="FacturaFormasPagoRef"
              :disabled="factura.detalleFactura.length == 0"
              :indVuelto="true"
              :valorMaximo="factura.montoTotal"
              :pagosInt="factura.pagos"
              :monedas="monedas"
              @GetPagos="GetPagos"
            >
            </FacturaFormasPago>
            <v-btn :disabled="factura.detalleFactura.length == 0" block color="primary" @click="abrirPagos()">
              Forma pago
              <v-icon right dark>
                {{ icons.mdiKeyboardF5 }}
              </v-icon>
            </v-btn>

            <v-list width="100%" style="max-height: 480px; overflow-y: auto">
              <h4 class="text-center" v-if="factura.pagos.length == 0">No hay pagos registrados</h4>
              <template v-for="(item, index) in factura.pagos">
                <v-list-item :key="index + 'pagos'">
                  <v-list-item-avatar size="30" color="primary" class="white--text">
                    {{ item.moneda.simbolo }}
                  </v-list-item-avatar>

                  <v-list-item-content class="p-0 m-0 text-left">
                    <v-list-item-subtitle class="py-0 my-0">{{ item.descripcion }} </v-list-item-subtitle>

                    <v-list-item-subtitle class="py-0 my-0">
                      {{
                        `${item.moneda.nombre} ${item.moneda.formaPago.find(i => i.id == item.formaPago).descripcion}`
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="py-0 my-0" v-if="item.moneda.tasa != 1">
                      <small
                        >Monto $: {{ Number(item.montoPagoReal).toFixed(2) }}/ tasa:
                        {{
                          item.moneda.indMultiplicar ? (1 / item.moneda.tasa).toFixed(2) : item.moneda.tasa.toFixed(2)
                        }}</small
                      >
                    </v-list-item-subtitle>

                    <v-list-item-subtitle class="py-0 my-0" v-if="item.referencia != ''"
                      ><small> Ref: {{ item.referencia }}</small>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="py-0 my-0 success--text"
                      v-if="item.vueltoInfo && Number(item.vueltoInfo.montoPagoMoneda) > 0"
                    >
                      <small
                        >Vuelto {{ item.vueltoInfo.moneda.simbolo }}:
                        {{ Number(item.vueltoInfo.montoPagoMoneda).toFixed(2) }}</small
                      >
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    {{ item.moneda.simbolo }} {{ Number(item.montoPagoMoneda).toFixed(2) }}
                  </v-list-item-action>
                </v-list-item>
                <v-divider :key="index"></v-divider>
              </template>
            </v-list>
          </v-card>
        </div>
        <div class="contenedor-productos-totales">
          <v-card height="23vh">
            <totales :visualizarMoneda="visualizarMoneda" :factura="factura"></totales>

            <v-btn :loading="finalizando" :disabled="!validarFinalizar()" block color="success" elevation="2"
              >Facturar
              <v-icon right>
                {{ icons.mdiPrinterCheck }}
              </v-icon>
              <v-icon right dark>
                {{ icons.mdiKeyboardF12 }}
              </v-icon>
            </v-btn>
          </v-card>
        </div>
      </v-col>
      <modal-editar-producto
        ref="ModalEditarProductoRef"
        @modificar="addDetalle"
        @eliminar="eliminarDetalle"
      ></modal-editar-producto>
      <ModalBusquedaProductoAvanzado
        idArea:datosCaja.value.caja.idArea
        @GetProductos="GetProductos"
        :columnas="['ID', 'NOMBRE', 'BARRA', 'EXISTENCIA']"
        :fullscreen="true"
        ref="ModalBusquedaProductoAvanzadoRef"
      ></ModalBusquedaProductoAvanzado>
      <ModalBuscarPedido @obtenerPedido="obtenerPedido" ref="ModalBuscarPedidoRef"></ModalBuscarPedido>
    </v-row>
    <div v-if="datosCaja.caja != undefined">
      <div class="text-center" v-if="store.state.sucursalSelect.id != datosCaja.caja.area.idSucursal">
        <v-icon size="80" color="error" class="mb-6">
          {{ icons.mdiEmoticonSadOutline }}
        </v-icon>

        <h3>No tiene una caja asignada en esta sucrusal</h3>
      </div>
    </div>
    <div class="text-center" v-if="datosCaja.caja == undefined">
      <v-icon size="80" color="error" class="mb-6">
        {{ icons.mdiEmoticonSadOutline }}
      </v-icon>
      <h4>No tiene una caja asignada en esta sucrusal</h4>
    </div>

    <v-dialog v-model="finalizando" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Guardando la factura por favor espere.
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BuscardorProductoBarra from '../../producto/catalogo/producto/componentes/BuscardorProductoBarra.vue'
import ListaProducto from './componentes/ListaProducto.vue'
import Totales from './componentes/Totales.vue'
import {
  mdiMagnify,
  mdiKeyboardF5,
  mdiKeyboardF1,
  mdiKeyboardF2,
  mdiKeyboardF3,
  mdiKeyboardF4,
  mdiKeyboardF12,
  mdiEmoticonSadOutline,
  mdiPrinterCheck,
} from '@mdi/js'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import FacturaCliente from './componentes/FacturaCliente.vue'
import FacturaFormasPago from './componentes/FacturaFormasPago.vue'
import SubTotales from './componentes/SubTotales.vue'
import FuncionesGenerales from '@/funciones/funciones'
import ModalEditarProducto from './componentes/ModalEditarProducto.vue'
import ModalBuscarPedido from './componentes/ModalBuscarPedido.vue'
import MonedaServices from '@/api/servicios/MonedaServices'
import ModalBusquedaProductoAvanzado from '../../producto/catalogo/producto/componentes/ModalBusquedaProductoAvanzado.vue'
import CajaServices from '@/api/servicios/CajaServices'
import FacturaServices from '@/api/servicios/FacturaServices'
import store from '@/store'
import data from '@/views/dashboard/datatable-data'
import ImprimirTermicaServices from '@/api/worker/ImprimirTermicaServices'

import Vue from 'vue'
export default {
  components: {
    ListaProducto,
    BuscardorProductoBarra,
    Totales,
    FacturaCliente,
    FacturaFormasPago,
    SubTotales,
    ModalEditarProducto,
    ModalBusquedaProductoAvanzado,
    ModalBuscarPedido,
  },
  watch: {
    '$store.state.sucursalSelect.id': function () {
      this.limpiar()
      this.cargarDatosCaja()
    },
  },
  setup() {
    const mdiHandBackLeft =
      'M3 16V5.75C3 5.06 3.56 4.5 4.25 4.5S5.5 5.06 5.5 5.75V12H6.5V2.75C6.5 2.06 7.06 1.5 7.75 1.5C8.44 1.5 9 2.06 9 2.75V12H10V1.25C10 .56 10.56 0 11.25 0S12.5 .56 12.5 1.25V12H13.5V3.25C13.5 2.56 14.06 2 14.75 2S16 2.56 16 3.25V15H16.75L18.16 11.47C18.38 10.92 18.84 10.5 19.4 10.31L20.19 10.05C21 9.79 21.74 10.58 21.43 11.37L18.4 19C17.19 22 14.26 24 11 24C6.58 24 3 20.42 3 16Z'
    const monedaPais = ref({})
    const monedaPrincipal = ref({})
    const ModalEditarProductoRef = ref(null)
    const FacturaClienteRef = ref(null)
    const ModalBusquedaProductoAvanzadoRef = ref(null)
    const ModalBuscarPedidoRef = ref(null)
    let identity = -10000
    const identityGlobal = () => {
      identity = identity + 1
      return identity
    }
    const FacturaFormasPagoRef = ref(null)

    const abrirModalBusquedaProductoA = () => {
      ModalBusquedaProductoAvanzadoRef.value.abrirModal(true)
    }
    const abrirModalBuscarPedido = () => {
      ModalBuscarPedidoRef.value.abrirModal()
    }
    const visualizarMoneda = ref(['PAIS', 'PRINCIPAL'])

    const Imprimir = (datos, datosFactura) => {
      console.log('Imprimir', datos)
      console.log('Imprimir2', datosFactura)
      const datosImprimir = [
        {
          texto: '/LOGO',
          texto2: store.state.user.empresaActual.imagen2,
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 10,
          alineacion: 'center',
        },
        {
          texto: store.state.user.empresaActual.razonSocial,
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 40,
          alineacion: 'center',
        },
        {
          texto: store.state.user.empresaActual.documento,
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 15,
          alineacion: 'center',
        },
        {
          texto: 'FECHA: ' + FuncionesGenerales.formatoFecha(datosFactura.fechaCreacion, 1),
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 25,
          alineacion: 'left',
        },
        {
          texto: 'HORA: ' + FuncionesGenerales.formatoFecha(datosFactura.fechaCreacion, 2),
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 15,
          alineacion: 'left',
        },
        {
          texto: 'NUMERO:' + datosFactura.numeroControl,
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 15,
          alineacion: 'left',
        },
        {
          texto: 'REF:' + datosFactura.numeroDocumento,
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 15,
          alineacion: 'left',
        },
        {
          texto: '/SEPARACION2',
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 15,
          alineacion: 'left',
        },
        {
          texto: 'RIF/CI: ' + (datosFactura.idCliente != 0 ? clienteSelect.value.documento : '123456789'),
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 15,
          alineacion: 'left',
        },
        {
          texto:
            'CLIENTE: ' +
            (datosFactura.idCliente != 0
              ? clienteSelect.value.nombre + ' ' + clienteSelect.value.apellido
              : 'Cliente General'),
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 15,
          alineacion: 'left',
        },
        {
          texto: '/SEPARACION1',
          fuente: 'Lucida Console',
          tamano: 8,
          color: 'black',
          separador: 15,
          alineacion: 'left',
        },
      ]

      datos.detalleFactura.forEach(element => {
        if (element.numeroUnidades == 1) {
          datosImprimir.push({
            texto: element.producto.producto.nombre,
            texto2: FuncionesGenerales.formatoNumeric(element.total),
            fuente: 'Lucida Console',
            tamano: 8,
            color: 'black',
            separador: 15,
            alineacion: 'left',
          })
        } else {
          datosImprimir.push({
            texto: element.producto.producto.nombre,
            texto2: '',
            fuente: 'Lucida Console',
            tamano: 8,
            color: 'black',
            separador: 15,
            alineacion: 'left',
          })
          datosImprimir.push({
            texto: element.numeroUnidades + ' X' + (element.pvp + element.iva),
            texto2: FuncionesGenerales.formatoNumeric(element.total),
            fuente: 'Lucida Console',
            tamano: 8,
            color: 'black',
            separador: 15,
            alineacion: 'left',
          })
        }
      })

      datosImprimir.push({
        texto: '/SEPARACION1',
        fuente: 'Lucida Console',
        tamano: 8,
        color: 'black',
        separador: 15,
        alineacion: 'left',
      })

      datosImprimir.push({
        texto: 'SUB TOTAL',
        texto2: FuncionesGenerales.formatoNumeric(datosFactura.montoSubTotal),
        fuente: 'Lucida Console',
        tamano: 8,
        color: 'black',
        separador: 15,
        alineacion: 'left',
      })
      datosImprimir.push({
        texto: 'IMPUESTO',
        texto2: FuncionesGenerales.formatoNumeric(datosFactura.montoIva),
        fuente: 'Lucida Console',
        tamano: 8,
        color: 'black',
        separador: 15,
        alineacion: 'left',
      })
      datosImprimir.push({
        texto: 'TOTAL',
        texto2: FuncionesGenerales.formatoNumeric(datosFactura.montoTotal),
        fuente: 'Lucida Console',
        tamano: 8,
        color: 'black',
        separador: 15,
        alineacion: 'left',
      })
      datosImprimir.push({
        texto: '/SEPARACION1',
        fuente: 'Lucida Console',
        tamano: 8,
        color: 'black',
        separador: 15,
        alineacion: 'left',
      })
      datosImprimir.push({
        texto: 'GRACIAS POR SU VISITA',
        fuente: 'Lucida Console',
        tamano: 8,
        color: 'black',
        separador: 15,
        alineacion: 'center',
      })

      ImprimirTermicaServices.Imprimir({ impresora: 'XP-58', printObjeto: datosImprimir })
        .then(response => {
          if (response.data.estatus) {
            store.commit('setAlert', {
              message: 'Se envio a la impresora',
              type: 'success',
            })
          } else {
            store.commit('setAlert', {
              message: 'Error Impresion' + response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: 'Error Impresion' + error,
            type: 'error',
          })
        })
    }
    onBeforeMount(() => {
      monedaPais.value = FuncionesGenerales.monedaPais()
      monedaPrincipal.value = FuncionesGenerales.monedaPrincipal()
      ConsultarMonedas()
      cargarDatosCaja()

      if (monedaPais.value.id == monedaPrincipal.value.id) {
        visualizarMoneda.value = ['PAIS']
      }
    })

    let counterConsultasMoneda = ref(0)
    watch(counterConsultasMoneda, () => {
      setTimeout(() => {
        ConsultarMonedas()
      }, 5000)
    })

    function round(num) {
      var m = Number((Math.abs(num) * 100).toPrecision(15))
      return (Math.round(m) / 100) * Math.sign(num)
    }
    const facturaInit = {
      id: -1 /*long*/,
      idPedido: -1 /*long*/,
      idCaja: -1 /*int*/,
      idArea: null /*int*/,
      idCliente: 0 /*int*/,
      idVendedor: null /*int*/,
      cantidadProductos: 0 /*int*/,
      observaciones: '' /*string*/,
      montoSubTotal: 0 /*decimal*/,
      montoIva: 0 /*decimal*/,
      montoDescuento: 0 /*decimal*/,
      montoIgtf: 0 /*decimal*/,
      montoTotal: 0 /*decimal*/,
      montoPagado: 0 /*decimal*/,
      montoSubTotalPais: 0 /*decimal*/,
      montoIvaPais: 0 /*decimal*/,
      montoDescuentoPais: 0 /*decimal*/,
      montoIgtfPais: 0 /*decimal*/,
      montoTotalPais: 0 /*decimal*/,
      montoPagadoPais: 0 /*decimal*/,
      diferenciaMonedaPais: 0 /*decimal*/,
      diferenciaMonedaReal: 0 /*decimal*/,
      tasa: 0 /*decimal*/,
      detalleFactura: [],
      pagos: [],
      resta: 0,
      restaPais: 0,
    }
    const detalleFacturaInit = {
      id: -1 /*long*/,
      idLote: 0 /*int*/,
      idProductoSucursal: 0 /*int*/,
      idProveedor: 0 /*int*/,
      idTasaImpuesto: 0 /*int*/,
      numeroUnidades: 1 /*decimal*/,
      pvpPais: 0 /*decimal*/,
      descuentoPais: 0 /*decimal*/,
      totalPais: 0 /*decimal*/,
      pvp: 0 /*decimal*/,
      descuento: 0 /*decimal*/,
      total: 0 /*decimal*/,
      tasaImpuestoValor: 0 /*decimal*/,
      iva: 0 /*decimal*/,
      ivaPais: 0 /*decimal*/,
    }

    const GetProductos = datos => {
      //RECORRER PARA VERRIFICAR EXISTENCIAS Y DUPLICIDAD
      datos.forEach(item => {
        console.log('RECORRER PARA VERRIFICAR EXISTENCIAS Y DUPLICIDAD')
        console.log(item)
        console.log(factura.value.detalleFactura)
        const detalle = factura.value.detalleFactura.find(
          it => it.idProductoSucursal == item.id && (item.lote == null || it.idLote == item.lote.id),
        )
        if (detalle != undefined) {
          addDetalle(
            {
              ...detalle,
              numeroUnidades: detalle.numeroUnidades + 1,
            },
            false,
          )
        } else {
          debugger
          addDetalle(
            {
              ...detalleFacturaInit,
              producto: {
                ...item,
                lote: item.lote
                  ? [
                      {
                        ...item.lote,
                      },
                    ]
                  : [],
              },
            },
            false,
          )
        }
      })
    }

    const pedidoObjeto = ref({})
    const obtenerPedido = dato => {
      pedidoObjeto.value = dato.value
      mostrarCliente(pedidoObjeto.value.cliente)
      console.log('PEDIDO', pedidoObjeto.value)
      factura.value.id = -1 /*long*/
      factura.value.idPedido = pedidoObjeto.value.id /*long*/
      factura.value.idCaja = datosCaja.value.idCaja /*int*/
      factura.value.idArea = datosCaja.value.caja.idArea /*int*/
      factura.value.idCliente = pedidoObjeto.value.idCliente /*int*/
      factura.value.idVendedor = pedidoObjeto.value.idVendedor /*int*/
      factura.value.cantidadProductos = pedidoObjeto.value.cantidadProductos /*int*/
      factura.value.observaciones = pedidoObjeto.value.observaciones /*string*/
      factura.value.montoSubTotal = pedidoObjeto.value.montoSubTotal /*decimal*/
      factura.value.montoIva = pedidoObjeto.value.montoIva /*decimal*/
      factura.value.montoDescuento = pedidoObjeto.value.montoDescuento /*decimal*/
      factura.value.montoIgtf = pedidoObjeto.value.montoIgtf /*decimal*/
      factura.value.montoTotal = pedidoObjeto.value.montoTotal /*decimal*/
      factura.value.montoPagado = pedidoObjeto.value.montoPagado /*decimal*/
      factura.value.montoSubTotalPais = pedidoObjeto.value.montoSubTotal * pedidoObjeto.value.tasa /*decimal*/
      factura.value.montoIvaPais = pedidoObjeto.value.montoIva * pedidoObjeto.value.tasa /*decimal*/
      factura.value.montoDescuentoPais = pedidoObjeto.value.montoDescuento * pedidoObjeto.value.tasa /*decimal*/
      factura.value.montoIgtfPais = pedidoObjeto.value.montoIgtf * pedidoObjeto.value.tasa /*decimal*/
      factura.value.montoTotalPais = pedidoObjeto.value.montoTotal * pedidoObjeto.value.tasa /*decimal*/
      factura.value.montoPagadoPais = pedidoObjeto.value.montoPagado * pedidoObjeto.value.tasa /*decimal*/
      factura.value.diferenciaMonedaPais = 0 /*decimal*/
      factura.value.diferenciaMonedaReal = 0 /*decimal*/
      factura.value.tasa = pedidoObjeto.value.tasa /*decimal*/
      factura.value.detalleFactura = []
      factura.value.pagos = []
      factura.value.resta = 0
      factura.value.restaPais = 0
      pedidoObjeto.value.detallePedido.forEach(element => {
        let _segment = {
          id: element.id,
          idLote: !element.lotes ? null : element.lotes.length == 1 ? element.lotes[0].id : null,
          idProductoSucursal: element.idProductoSucursal,
          idProveedor: null,
          idTasaImpuesto: element.producto.idTasaImpuesto,
          idProveedor: element.idProveedor,
          producto: {
            existencia: element.existencia,
            codigoLote: null,
            id: element.producto.id,
            nombre: element.producto.nombre,
            idProducto: element.idProducto,
            idSucursal: 1,
            indActivo: true,
            costo: 0,
            factorCosto2: 0,
            costo2: 0,
            factorCosto3: 0,
            costo3: 0,
            factorVenta: 0,
            pvp: element.pvp,
            indActivoVenta: true,
            indActivoCompra: true,
            indIVA: true,
            checked: null,
            proveedor: null,
            sucursal: null,
            producto: element.producto,
            lote: !element.lotes ? [] : element.lotes.length == 1 ? [...element.lotes[0]] : [],
          },
          lotes: element.lotes,
          tasaImpuestoValor: element.tasaImpuestoValor,
          numeroUnidades: element.numeroUnidades,
          pvpPais: element.pvp * factura.value.tasa,
          pvp: element.pvp,
          descuento: 0,
          descuentoPais: 0,
          subTotal: element.pvp * element.numeroUnidades,
          subTotalPais: element.pvp * element.numeroUnidades * factura.value.tasa,
          iva: element.iva,
          ivaPais: element.iva * factura.value.tasa,
          totalPais: (element.pvp + element.iva) * element.numeroUnidades * factura.value.tasa,
          total: (element.pvp + element.iva) * element.numeroUnidades,
        }
        factura.value.detalleFactura.push(_segment)
      })

      pedidoObjeto.value.pagoPedido.forEach(element => {
        let _segment = {
          id: element.id,
          moneda: {
            id: element.formaPago.moneda.id,
            idTipoUsoMoneda: element.formaPago.moneda.id,
            idPais: element.formaPago.moneda.idPais,
            nombre: element.formaPago.moneda.nombre,
            descripcion: element.formaPago.moneda.descripcion,
            simbolo: element.formaPago.moneda.simbolo,
            tasa: element.tasa,
            indPrincipal: element.formaPago.moneda.indPrincipal,
            idActivo: element.formaPago.moneda.idActivo,
            indPais: element.formaPago.moneda.indPais,
            tipoUsoMoneda: null,
            pais: null,
            formaPago: [
              {
                id: element.formaPago.id,
                idFormaPagoGeneral: element.formaPago.idFormaPagoGeneral,
                idMoneda: element.formaPago.idMoneda,
                descripcion: element.formaPago.descripcion,
                maximoMontoCambio: element.formaPago.maximoMontoCambio,
                minimoMontoVoucher: element.formaPago.minimoMontoVoucher,
                montoMinimo: element.formaPago.montoMinimo,
                montoMaximo: element.formaPago.montoMaximo,
                indReconversion: element.formaPago.indReconversion,
                factorReconversion: element.formaPago.factorReconversion,
                indSolicitarBanco: element.formaPago.indSolicitarBanco,
                idUsuarioCrea: element.formaPago.idUsuarioCrea,
                idUsuarioModifica: element.formaPago.idUsuarioModifica,
                fechaCreacion: element.formaPago.fechaCreacion,
                fechaModificacion: element.formaPago.fechaModificacion,
                formaPagoGeneral: element.formaPago.formaPagoGeneral,
                moneda: element.formaPago.moneda,
              },
            ],
          },
          formaPago: element.idFormaPago,
          montoPagoMoneda: element.montoPagoMoneda,
          monto: 0,
          referencia: element.referencia,
          montoPagoReal: element.montoPagoReal,
          nuevo: true,
          fecha: element.fechaPago,
          igtf: element.igtf,
          vueltoInfo: {},
          idFormaPago: element.idFormaPago,
          montoPagoPais: element.montoPagoPais,
          tasa: element.tasa,
        }
        factura.value.pagos.push(_segment)
      })
      totalizarFactura()
      console.log('FACTURA OBJETO', factura.value)
    }
    const mostrarCliente = dato => {
      FacturaClienteRef.value.mostrarCliente(dato, factura.value.idPedido)
    }
    const cargandoCaja = ref(false)
    const datosCaja = ref({})
    const cargarDatosCaja = () => {
      cargandoCaja.value = true
      CajaServices.ConsultarCajaUsuario({
        idUsuario: store.state.user.idUsuario,
        idSucursal: store.state.sucursalSelect.id,
      })
        .then(response => {
          if (response.data.estatus == true) {
            if (response.data.datos.idCaja != undefined) {
              datosCaja.value = response.data.datos
            } else {
              store.commit('setAlert', {
                message: 'No hubo resuesta',
                type: 'warning',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
            message: err,
            type: 'error',
          })
        })
        .finally(() => {
          cargandoCaja.value = false
        })
    }

    const GetPagos = pagosIn => {
      let _montoPagado = 0
      let _montoPagadoPais = 0
      let _montoIgtf = 0

      pagosIn.forEach(i => {
        _montoPagado += Number(i.montoPagoReal)
        if (monedaPais.value.id == i.moneda.id) {
          _montoPagadoPais += Number(i.montoPagoMoneda)
        } else {
          _montoPagadoPais += Number(i.montoPagoReal) * monedaPais.value.tasa
        }

        _montoIgtf += Number(i.igtf)
      })
      console.log(_montoPagado)
      console.log(_montoPagadoPais)
      factura.value.pagos = pagosIn
      factura.value.montoPagado = _montoPagado
      factura.value.montoPagadoPais = _montoPagadoPais
      factura.value.montoIgtf = _montoIgtf
      factura.value.montoIgtfPais = _montoIgtf * monedaPais.value.tasa
      factura.value.resta = factura.value.montoTotal + factura.value.montoIgtf - factura.value.montoPagado
      factura.value.restaPais =
        factura.value.montoTotalPais + factura.value.montoIgtfPais - factura.value.montoPagadoPais
    }
    const clienteSelect = ref(null)
    const Getcliente = item => {
      //alert('cliente')
      if (item) {
        clienteSelect.value = item
        factura.value.idCliente = item.id
      } else {
        factura.value.idCliente = 0
      }
    }
    const limpiar = () => {
      factura.value = { ...facturaInit, pagos: [], detalleFactura: [] }
      FacturaClienteRef.value.limpiar()
    }
    function delay(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    }
    const validarFinalizar = () => {
      var ret = true
      if (factura.value.detalleFactura.length == 0) {
        ret = false
      } else if (factura.value.idCliente == null) {
        ret = false
      } else if (round(factura.value.resta) > 0) {
        ret = false
      } else if (store.state.sucursalSelect.id != datosCaja.value.caja.area.idSucursal) {
        ret = false
      }
      return ret
    }
    const finalizando = ref(false)
    const finalizar = async () => {
      if (!finalizando.value) {
        try {
          finalizando.value = true

          await delay(2000)

          if (factura.value.detalleFactura.length == 0) {
            store.commit('setAlert', {
              message: 'No hay productos a facturar',
              type: 'info',
            })
          } else if (factura.value.idCliente == null) {
            store.commit('setAlert', {
              message: 'Debe selecionar un cliente',
              type: 'info',
            })
          } else if (round(factura.value.resta) > 0) {
            store.commit('setAlert', {
              message: 'Debe completar todo el pago',
              type: 'info',
            })
          } else if (store.state.sucursalSelect.id != datosCaja.value.caja.area.idSucursal) {
            store.commit('setAlert', {
              message: 'Sucursal incorrecta, ud es cajero de la sucursal: ' + datosCaja.value.caja.area.sucursal.nombre,
              type: 'info',
            })
          } else {
            let pagos = []
            pagos = JSON.parse(JSON.stringify(factura.value.pagos))
            console.log('pagos', factura.value.pagos)
            factura.value.pagos.forEach(pagg => {
              if (pagg.vueltoInfo.montoPagoMoneda > 0) {
                pagos.push({
                  fecha: pagg.fecha,
                  formaPago: pagg.formaPago,
                  id: pagg.id * -1,
                  idFormaPago: pagg.formaPago,
                  igtf: 0,
                  moneda: pagg.vueltoInfo.moneda,
                  monto: 0,
                  montoPagoMoneda: pagg.vueltoInfo.montoPagoMoneda * -1,
                  montoPagoPais: pagg.vueltoInfo.montoPagoReal * -1,
                  montoPagoReal: pagg.vueltoInfo.montoPagoReal * -1,

                  tasa: pagg.vueltoInfo.moneda.tasa,
                })
              }
            })

            console.log(factura.value)
            await FacturaServices.Actualizar(store.state.user.idUsuario, { ...factura.value, pagoFactura: pagos })
              .then(response => {
                if (response.data.estatus == true) {
                  store.commit('setAlert', {
                    message: response.data.mensaje,
                    type: 'success',
                  })

                  if (store.state.workerStatus) {
                    Imprimir({ ...factura.value, pagoFactura: pagos }, response.data.datos.datos)
                  } else {
                    store.commit('setAlert', {
                      message: 'Se guardo con exito, No se envio a impresora',
                      type: 'info',
                    })
                  }
                  limpiar()
                } else {
                  store.commit('setAlert', {
                    message: response.data.datos.mensajeError,
                    type: 'warning',
                  })
                }
              })
              .catch(err => {
                store.commit('setAlert', {
                  message: err,
                  type: 'warning',
                })
              })
              .finally(() => {
                finalizando.value = false
              })
          }
          finalizando.value = false
        } catch (error) {
          finalizando.value = false
          store.commit('setAlert', {
            message: error,
            type: 'error',
          })
        }
      }
    }

    const totalizarFactura = () => {
      let montoSubTotal = 0.0
      let montoIva = 0.0
      let montoDescuento = 0.0
      let montoIgtf = 0.0
      let montoTotal = 0.0
      let montoPagado = 0.0
      let montoSubTotalPais = 0.0
      let montoIvaPais = 0.0
      let montoDescuentoPais = 0.0
      let montoIgtfPais = 0.0
      let montoTotalPais = 0.0
      let montoPagadoPais = 0.0
      let diferenciaMonedaPais = 0.0
      let diferencia = 0.0
      let resta = 0.0
      let restaPais = 0.0

      factura.value.detalleFactura.forEach(element => {
        montoSubTotalPais += element.numeroUnidades * element.pvpPais - element.descuentoPais
        montoIvaPais += element.ivaPais

        montoSubTotal += element.numeroUnidades * element.pvp - element.descuento
        montoIva += element.iva
      })

      let _montoPagado = 0
      let _montoPagadoPais = 0
      let _montoIgtf = 0

      factura.value.pagos.forEach(i => {
        _montoPagado += Number(i.montoPagoReal)
        _montoPagadoPais += Number(i.montoPagoReal) * monedaPais.value.tasa
        _montoIgtf += Number(i.igtf)
      })

      montoTotal = montoSubTotal - montoDescuento + montoIva + montoIgtf
      montoTotalPais = montoSubTotalPais - montoDescuentoPais + montoIvaPais + montoIgtfPais
      resta = montoTotal - montoPagado
      restaPais = montoPagadoPais - montoTotalPais
      diferenciaMonedaPais = montoTotal * monedaPais.value.tasa - montoTotalPais
      diferencia = diferenciaMonedaPais / monedaPais.value.tasa
      let _diferencia = diferencia < 0 ? diferencia * -1 : diferencia
      montoTotal += diferencia * -1 + (diferencia < 0 && _diferencia > 0.0009 ? 0.01 : 0) //+ (diferencia > 0 && _diferencia > 0.0009 ? -0.01 : 0)

      // //alert('caso 1 :' + (diferencia < 0 &&  _diferencia  > 0.0009 ? 0.01 :0) )
      // //alert('caso 2 :' + (diferencia > 0 &&  _diferencia > 0.0009 ? -0.01 :0) )

      factura.value = {
        ...factura.value,
        idCaja: datosCaja.value.idCaja,
        tasa: monedaPais.value.tasa,
        montoSubTotal,
        montoIva,
        montoDescuento,
        montoIgtf,
        montoTotal,
        montoPagado,
        montoSubTotalPais,
        montoIvaPais,
        montoDescuentoPais,
        montoIgtfPais,
        montoTotalPais,
        montoPagadoPais,
        diferenciaMonedaPais,
        diferencia,
        resta,
        restaPais,
        cantidadProductos: factura.value.detalleFactura.length,
      }
      debugger
      factura.value.montoPagado = _montoPagado
      factura.value.montoPagadoPais = _montoPagadoPais
      factura.value.montoIgtf = _montoIgtf
      factura.value.montoIgtfPais = _montoIgtf * monedaPais.value.tasa
      factura.value.resta = factura.value.montoTotal + factura.value.montoIgtf - factura.value.montoPagado
      factura.value.restaPais =
        factura.value.montoTotalPais + factura.value.montoIgtfPais - factura.value.montoPagadoPais
    }

    const factura = ref({ ...facturaInit })
    const custom_sort = (a, b) => {
      return new Date(a.fechaModificacion) - new Date(b.fechaModificacion)
    }
    const BuscardorProductoBarraRef = ref(null)
    const monedas = ref([])
    const carcangoMondedas = ref(false)
    let maximaFechaMoneda = ''
    const ConsultarMonedas = (incrementar = true) => {
      carcangoMondedas.value = true
      try {
        MonedaServices.ConsultarSucursal({ idSucursal: store.state.sucursalSelect.id })

          .then(response => {
            if (response.data.estatus) {
              const fecha = response.data.datos.sort(custom_sort)

              if (maximaFechaMoneda != fecha[0].fechaModificacion) {
                console.log('cambio de moneda')
                maximaFechaMoneda = fecha[0].fechaModificacion
                monedas.value = response.data.datos
              }
            } else {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'warning',
              })
            }
          })
          .catch(err => {
            store.commit('setAlert', {
              message: err,
              type: 'error',
            })
          })
          .finally(() => {
            carcangoMondedas.value = false
            if (incrementar == true) {
              counterConsultasMoneda.value += 1
            }
          })
      } catch (error) {
        carcangoMondedas.value = false
        store.commit('setAlert', {
          message: error,
          type: 'error',
        })
        if (incrementar == true) {
          counterConsultasMoneda.value += 1
        }
      }
    }

    const producto = ref({})

    const modificarDetalle = item => {
      ModalEditarProductoRef.value.abrir({ ...item })
    }

    const abrirPagos = () => {
      FacturaFormasPagoRef.value.abrirModal()
    }

    watch(producto, () => {
      if (producto.value.id != undefined) {
        const detalle = factura.value.detalleFactura.find(
          it =>
            it.idProductoSucursal == producto.value.id &&
            (producto.value.lote == null || it.idLote == producto.value.lote[0].id),
        )
        if (detalle != undefined) {
          addDetalle(
            {
              ...detalle,
              numeroUnidades: Number(detalle.numeroUnidades) + 1,
            },
            false,
          )
        } else {
          addDetalle({
            ...detalleFacturaInit,
            producto: producto.value,
          })
        }
      }
    })

    const eliminarDetalle = detalle => {
      factura.value.detalleFactura = factura.value.detalleFactura.filter(i => i.id != detalle.id)
      totalizarFactura()
    }

    const addDetalle = (detalle, open = true) => {
      debugger
      console.log(detalle.producto)
      let _existencia = 0
      if (detalle.producto.existencia.inventario > 0) {
        _existencia = detalle.producto.existencia.inventario
      } else {
        _existencia = 0
      }
      if (detalle.producto.lote.length > 0 && detalle.producto.lote[0].id != undefined) {
        if (detalle.producto.lote[0].existenciaLote.inventario > 0) {
          _existencia = detalle.producto.lote[0].existenciaLote.inventario
        } else {
          _existencia = 0
        }
      }

      if (detalle.numeroUnidades <= _existencia) {
        if (detalle.id == -1) {
          let tasaImpuestoValor = datosCaja.value.caja.area.sucursal.indAplicaImpuestos
            ? detalle.producto.producto.tasaImpuesto.valor
            : 0 /*decimal*/
          let numeroUnidades = 1
          let pvpPais = round(round(detalle.producto.pvp) * FuncionesGenerales.monedaPais().tasa) /*decimal*/
          let pvp = round(detalle.producto.pvp) /*decimal*/
          let descuento = round(0) /*decimal*/
          let descuentoPais = round(0) /*decimal*/
          let subTotal = round(numeroUnidades * pvp)
          let subTotalPais = round(numeroUnidades * pvpPais)
          let iva = round((subTotal - descuentoPais) * round(tasaImpuestoValor / 100)) /*decimal*/
          let ivaPais = round((subTotalPais - descuento) * round(tasaImpuestoValor / 100)) /*decimal*/
          let totalPais = round(subTotalPais + ivaPais - descuentoPais) /*decimal*/
          let total = round(subTotal + iva - descuento) /*decimal*/
          let id = identityGlobal()
          const detalleNew = {
            id: id /*long*/,
            idLote:
              detalle.producto.producto.indLote == false
                ? null
                : detalle.producto.lote.length > 0
                ? detalle.producto.lote[0].id
                : null /*int*/,
            idProductoSucursal: detalle.producto.id /*int*/,
            idProveedor: detalle.producto.idProveedor /*int*/,
            idTasaImpuesto: detalle.producto.producto.idTasaImpuesto /*int*/,
            producto: { ...detalle.producto },
            tasaImpuestoValor,
            numeroUnidades,
            pvpPais,
            pvp,
            descuento,
            descuentoPais,
            subTotal,
            subTotalPais,
            iva,
            ivaPais,
            totalPais,
            total,
          }
          factura.value.detalleFactura.push(detalleNew)
          producto.value = {}
          totalizarFactura()

          if (open == true) {
            ModalEditarProductoRef.value.abrir({ ...detalleNew })
          }
        } else {
          let tasaImpuestoValor = detalle.tasaImpuestoValor /*decimal*/
          let numeroUnidades = Number(detalle.numeroUnidades)
          let pvpPais = round(round(detalle.pvp) * FuncionesGenerales.monedaPais().tasa) /*decimal*/
          let pvp = round(detalle.pvp) /*decimal*/
          let descuento = round(0) /*decimal*/
          let descuentoPais = round(0) /*decimal*/
          let subTotal = round(numeroUnidades * pvp)
          let subTotalPais = round(numeroUnidades * pvpPais)
          let iva = round((subTotal - descuentoPais) * round(tasaImpuestoValor / 100)) /*decimal*/
          let ivaPais = round((subTotalPais - descuento) * round(tasaImpuestoValor / 100)) /*decimal*/
          let totalPais = round(subTotalPais + ivaPais - descuentoPais) /*decimal*/
          let total = round(subTotal + iva - descuento) /*decimal*/

          const detalleNew = {
            ...detalle,
            tasaImpuestoValor,
            numeroUnidades,
            pvpPais,
            pvp,
            descuento,
            descuentoPais,
            subTotal,
            subTotalPais,
            iva,
            ivaPais,
            totalPais,
            total,
          }

          console.log('aquiiiiii 1', detalle)
          console.log('aquiiiiii 2', detalleNew)
          const index = factura.value.detalleFactura.findIndex(i => i.id == detalle.id)

          console.log('aquiiiiii ', index)
          factura.value.detalleFactura.splice(index, 1, detalleNew)
          totalizarFactura()
        }
      } else {
        store.commit('setAlert', {
          message: `la existencia disponible es de ${_existencia}`,
          type: 'error',
        })
      }
    }

    const LimpiarPregunta = () => {
      Vue.swal({
        icon: 'warning',
        html: '<h3>¿Seguro que quiere cancelar la factura? </h3>',
        showCancelButton: true,
        confirmButtonText: 'Si, Seguro',
        showLoaderOnConfirm: true,
        cancelButtonText: 'No',
        preConfirm: () => {
          limpiar()
          return false
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(result => {
        return false
      })
    }

    document.onkeydown = function (event) {
      event = event || window.event

      //TECLA enter
      if (event.keyCode == 13) {
        if (
          BuscardorProductoBarraRef.value.dato == '' &&
          !ModalBusquedaProductoAvanzadoRef.value.dialog &&
          !FacturaFormasPagoRef.value.dialogModal
        ) {
          BuscardorProductoBarraRef.value.focus1()
          return false
        }
        if (ModalBusquedaProductoAvanzadoRef.value.dialog == true) {
        }
        if (FacturaFormasPagoRef.value.dialogModal == true) {
          if (
            Number(
              (
                FacturaFormasPagoRef.value.valorMaximo -
                FacturaFormasPagoRef.value.TotalPago() +
                FacturaFormasPagoRef.value.TotalPagoIgtf()
              ).toFixed(2),
            ) > 0
          ) {
            FacturaFormasPagoRef.value.AgregarPago()
          } else {
            FacturaFormasPagoRef.value.guardarPagos()
          }
        }
      }
      //TECLA escape
      if (event.keyCode == 27) {
        LimpiarPregunta()

        return false
      }
      //TECLA F1
      if (event.keyCode == 112) {
        try {
          ModalBusquedaProductoAvanzadoRef.value.abrirModal(true)
        } catch (error) {}
        return false
      }
      //TECLA F2
      if (event.keyCode == 113) {
        //alert('F2')
        return false
      }
      //TECLA F3
      if (event.keyCode == 114) {
        //alert('F3')
        return false
      }
      //TECLA F4
      if (event.keyCode == 115) {
        if (FacturaClienteRef.value.cliente == null) {
          FacturaClienteRef.value.focus()
        }

        return false
      }
      //TECLA F5
      if (event.keyCode == 116) {
        if (factura.value.detalleFactura.length > 0) {
          FacturaFormasPagoRef.value.abrirModal()
        }
        return false
      }
      //TECLA F6
      if (event.keyCode == 117) {
        //alert('F7')
        return false
      }
      //TECLA F7
      if (event.keyCode == 118) {
        //alert('F8')
        return false
      }
      //TECLA F8
      if (event.keyCode == 119) {
        //alert('F9')
        return false
      }
      //TECLA F9
      if (event.keyCode == 120) {
        //alert('F9')
        return false
      }
      //TECLA F10
      if (event.keyCode == 121) {
        //alert('F10')
        return false
      }
      //TECLA F11
      if (event.keyCode == 122) {
        //alert('F11')
        return false
      }
      //TECLA F12
      if (event.keyCode == 123) {
        finalizar()
        return false
      }

      /* if (event.keyCode == 107) {
         if (tab.value == 'tab-2') {
           console.log('mas')
           listaDetallesProductosDetalleRef.value.IncrementarCantidad(1)
         }
         return false
       }
       if (event.keyCode == 109) {
         if (tab.value == 'tab-2') {
           console.log('menos')
           listaDetallesProductosDetalleRef.value.IncrementarCantidad(-1)
         }
         return false
       }*/
    }
    return {
      icons: {
        mdiMagnify,
        mdiKeyboardF5,
        mdiKeyboardF1,
        mdiKeyboardF2,
        mdiKeyboardF3,
        mdiKeyboardF4,
        mdiKeyboardF12,
        mdiHandBackLeft,
        mdiEmoticonSadOutline,
        mdiPrinterCheck,
      },
      BuscardorProductoBarraRef,
      monedas,
      producto,
      factura,
      ModalEditarProductoRef,
      ModalBuscarPedidoRef,
      modificarDetalle,
      addDetalle,
      FacturaClienteRef,
      abrirModalBusquedaProductoA,
      abrirModalBuscarPedido,
      ModalBusquedaProductoAvanzadoRef,
      GetProductos,
      FacturaFormasPagoRef,
      GetPagos,
      abrirPagos,
      eliminarDetalle,
      visualizarMoneda,
      Getcliente,
      finalizar,
      cargarDatosCaja,
      datosCaja,
      limpiar,
      store,
      obtenerPedido,
      pedidoObjeto,
      mostrarCliente,
      validarFinalizar,
      Imprimir,
      finalizando,
    }
  },
}
</script>
<style scoped>
.contenedor-productos {
  height: calc(67vh - 100px);
  overflow-x: hidden;
  overflow-y: auto;
}

.contenedor-productos-buscador {
  height: 10vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.contenedor-productos-sub-totales {
  height: 23vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.contenedor-productos-totales {
  height: 23vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.contenedor-productos-cliente {
  height: 20vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.contenedor-productos-pago {
  height: calc(57vh - 91px);
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
