<template>
    <div>

        <v-autocomplete :prepend-icon="icon ? icons.mdiAlphaCCircle : null" :clearable="clearable" hide-details="auto"
            :disabled="disabled" v-model="select" :items="lista" label="Estado" item-value="id" item-text="nombre"
            :dense="outlined" :outlined="outlined" :loading="cargando" :rules="rules">

        </v-autocomplete>
    </div>

</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlphaCCircle, mdiPlusCircleOutline } from '@mdi/js'
import EstadoServices from '@/api/servicios/EstadoServices'
export default {

    props: {
        value: Object,
        disabled: Boolean,
        dense: Boolean,
        outlined: Boolean,
        icon: Boolean,
        todos: Boolean,
        default: Number,
        clearable: Boolean,
        padre: Number,
        rules: Array
    },

    watch: {
        padre: function (newVal, oldVal) {

            if (newVal != oldVal) {
                this.cargarDatos()
            }
        },
        value: function (newVal, oldVal) {

            if (newVal != undefined && newVal != null) {
                this.select = newVal.id
                this.returnDato()
            } else {
                this.select = null
            }

        }
    },
    setup(props, contex) {
        const lista = ref([])
        const select = ref(null)
        const cargando = ref(false)
        let init = false

        onBeforeMount(() => {
            init = true
            cargarDatos()
            if (props.value != undefined) {
                select.value = props.value.id
                
                returnDato()
            }
        })

        const selectId = (id) => {

            select.value = id
        }
        const cargarDatos = () => {
            lista.value = []
            cargando.value = true
            try {

                EstadoServices.Consultar({
                    id: -1,
                    idPais: props.padre == null ? 0 : props.padre
                }).then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {
                        lista.value = response.data.datos
                        if (select.value != null && init == true) {
                            init = false
                            const valor = lista.value.find(item => (item.id == select.value))
                            contex.emit('input', valor)
                        }
                    }
                })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        cargando.value = false
                    })
            } catch (error) {

                cargando.value = false
            }
        }
        const returnDato = () => {
            if (lista.value.length > 0) {
                const valor = lista.value.find(item => (item.id == select.value))
                contex.emit('input', valor)
            }
        }
        watch(select, () => {
            returnDato()
        })


        return {
            icons: {
                mdiAlphaCCircle,
                mdiPlusCircleOutline
            },
            lista,
            select,
            cargando,
            selectId,
            cargarDatos,
            returnDato
        }
    },
}
</script>