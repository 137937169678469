<template  >
    <v-card class="mx-auto" style=" height: 20vh;" outlined>


        <v-card-text v-if="cliente">
            <p class="py-0 my-0">#:{{ cliente.id }} <v-btn v-if="!indPedido" icon color="primary" @click="limpiar()">
                    <v-icon>{{ icons.mdiCached }}</v-icon></v-btn> </p>
            <p class="py-0 my-0"><strong>Nombre:</strong> {{ cliente.nombre }} {{ cliente.apellido }}</p>
            <p class="py-0 my-0"><strong>Documento:</strong> {{ cliente.tipoDocumento.abreviatura }}-{{
                cliente.documento
            }}
            </p>
            <p class="py-0 my-0" v-if="cliente.direcciones"><strong>Direccion:</strong>
                {{ cliente.direcciones[0].direccionExtendida }} </p>
        </v-card-text>
        <v-card-text class="text-center" v-else>
            <SelectCliente ref="SelectClienteRef" @GetResult="Getcliente"></SelectCliente>
            <v-btn color="primary" small block @click="SelectClienteRef.abrirModal()">Nuevo cliente</v-btn>
        </v-card-text>
    </v-card>
</template>
<script>
import { mdiKeyboardF4, mdiCached } from '@mdi/js'
import SelectCliente from '@/views/sistema/crm/cliente/componentes/Select.vue';
import { ref } from '@vue/composition-api';
export default {
    components: {
        SelectCliente
    },
    setup(props, context) {
        const cliente = ref(null)
        const indPedido = ref(false);
        const Getcliente = (item) => {
           // alert("cliente")
            cliente.value = item
            context.emit('Getcliente', item)
        }
        const limpiar = () => {
            cliente.value = null
            context.emit('Getcliente', null)
        }
        const SelectClienteRef = ref(null)

        const focus = () => {
            SelectClienteRef.value.focus()
        }
        const mostrarCliente = (dato, idPedido) => {
            indPedido.value = idPedido ? true : false;
            cliente.value = dato;
        }
        return {
            limpiar,
            Getcliente,
            mostrarCliente,
            cliente,
            SelectClienteRef,
            focus,
            icons: {
                mdiKeyboardF4,
                mdiCached
            }
        }
    },
}
</script>