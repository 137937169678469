<template>
  <div>
    <v-expand-transition>
      <div v-show="showFiltros">
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="3">
            <TipoOrigenProdcutoSelect v-model="dato.IdTipoOrigenProducto" :clearable="true" :outlined="true">
            </TipoOrigenProdcutoSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <TipoProdcutoSelect v-model="dato.IdTipoProducto" :clearable="true" :outlined="true"> </TipoProdcutoSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <TasaImpuestoSelect :padre="1" v-model="dato.IdTasaImpuesto" :clearable="true" :outlined="true">
            </TasaImpuestoSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <UnidadMedidaSelect v-model="dato.IdUnidadMedida" :clearable="true" :outlined="true"> </UnidadMedidaSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <MarcaSelect v-model="dato.IdMarca" :clearable="true" :outlined="true"> </MarcaSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <ModeloSelect v-model="dato.IdModelo" :clearable="true" :outlined="true"> </ModeloSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <ColorSelect v-model="dato.IdColor" :clearable="true" :outlined="true"> </ColorSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <CategoriaSelect v-model="dato.IdCategoria" :clearable="true" :outlined="true"> </CategoriaSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SubCategoriaSelect
              v-model="dato.IdSubCategoria"
              :disabled="dato.IdCategoria == null ? true : false"
              :padre="dato.IdCategoria"
              :clearable="true"
              :outlined="true"
            >
            </SubCategoriaSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <LineaSelect v-model="dato.IdLinea" :clearable="true" :outlined="true"> </LineaSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SubLineaSelect
              v-model="dato.IdSubLinea"
              :disabled="dato.IdLinea == null ? true : false"
              :padre="dato.IdLinea"
              :clearable="true"
              :outlined="true"
            ></SubLineaSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <PaisSelect v-model="dato.IdPais" :clearable="true" :outlined="true"> </PaisSelect>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
    <v-row>
      <v-col cols="12" lg="2">
        <v-btn @click="showFiltros = !showFiltros" block>
          <v-icon left dark>
            {{ !showFiltros ? icons.mdiFilter : icons.mdiFilterOff }}
          </v-icon>
          {{ !showFiltros ? 'Ver' : 'Ocultar' }}
          Filtros
        </v-btn>
      </v-col> 
      <v-col cols="12" md="8" lg="8">
        <v-text-field
          ref="NombreRef"
          dense
          outlined
          label="Descripcion"
          v-model="dato.Nombre"
          autofocus
          placeholder="Nombre/Descripcion/Barra/Ref..."
          v-on:keyup.enter="buscar()"
          :loading="buscando"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" lg="2">
        <v-btn color="primary" block @click="buscar()" :loading="buscando"> Buscar</v-btn>
      </v-col>
      </v-row>
      <v-col cols="12">
        <v-row>
          <v-col :lg="seleccionMultiple ? 8 : 12" >
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-card-subtitle>Producto </v-card-subtitle>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      hide-details="auto"
                      class="my-0 py-0"
                      v-model="showOnlyExistencia"
                      :label="`Solo con Existencia`"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>

              <template>
                <v-simple-table fixed-header id="scroll-target" style="height: calc(100vh - 250px)" class="overflow-y-auto" dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" v-if="columnas.includes('ID')">#</th>
                        <th class="text-left" v-if="columnas.includes('BARRA')">Barra</th>
                        <th class="text-left" v-if="columnas.includes('CODIGO')">Codigos</th>
                        <th class="text-left" v-if="columnas.includes('NOMBRE')">Producto</th>

                        <th class="text-left" v-if="columnas.includes('CARACTERISTICA')">Caracteristicas</th>
                        <th class="text-left" v-if="columnas.includes('CATEGORIA')">Categoria</th>
                        <th class="text-left" v-if="columnas.includes('LINEA')">Linea</th>
                        <th class="text-left" v-if="columnas.includes('EXISTENCIA')">Existencia</th>
                        <th class="text-right" v-if="columnas.includes('EXISTENCIA')">PVP</th>
                      </tr>
                    </thead>
                    <tbody v-scroll:#scroll-target="onScroll">
                      <tr
                        v-for="(item, i) in filterProductos()"
                        :key="i"
                        :class="colorRow(item, i)"
                        @click="selectProducto(item)"
                      >
                        <td class="text-center" v-if="columnas.includes('ID')">
                          <p class="py-0 my-0">
                            <v-simple-checkbox
                              @click="selectProducto(item)"
                              color="success"
                              readonly
                              :value="item.checked"
                            ></v-simple-checkbox>
                          </p>
                        </td>
                        <td v-if="columnas.includes('BARRA')">
                          <small> {{ item.producto.barra }}</small>
                        </td>
                        <td v-if="columnas.includes('REF')">
                          <small> {{ item.producto.referencia }}</small>
                        </td>
                        <td v-if="columnas.includes('CODIGO')">
                          <p class="py-0 my-0">
                            <small>Barra: {{ item.producto.barra }}</small>
                          </p>
                          <p class="py-0 my-0" v-if="item.producto.referencia">
                            <small>Ref: {{ item.producto.referencia }}</small>
                          </p>
                          <p class="py-0 my-0" v-if="item.producto.referenciaFabrica">
                            <small>Ref-Fab: {{ item.producto.referenciaFabrica }}</small>
                          </p>
                        </td>
                        <td v-if="columnas.includes('NOMBRE')">
                          <small class="py-0 my-0">{{ item.producto.nombre }} </small> <br />
                          <small class="py-0 my-0" v-if="item.lote"
                            ><strong
                              >Lote: {{ item.lote.numeroLote }}
                              {{ funciones.formatoFecha(item.lote.fechaVencimiento) }}</strong
                            >
                          </small>
                        </td>

                        <td v-if="columnas.includes('CARACTERISTICA')">
                          <v-list-item three-line class="py-0 my-0">
                            <v-list-item-content class="py-0 my-0">
                              <v-list-item-subtitle class="my-0 py-0">
                                <small>Color: {{ item.producto.color.descripcion }}</small>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                <small>Marca: {{ item.producto.modelo.descripcion }}</small>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                <small>Modelo: {{ item.producto.modelo.descripcion }}</small>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </td>
                        <td v-if="columnas.includes('CATEGORIA')">
                          <v-list-item three-line class="py-0 my-0">
                            <v-list-item-content class="py-0 my-0">
                              <v-list-item-subtitle class="my-0 py-0">
                                <small>Cat: {{ item.producto.categoria.descripcion }}</small>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                <small>SubCat: {{ item.producto.subCategoria.descripcion }}</small>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </td>
                        <td v-if="columnas.includes('LINEA')">
                          <v-list-item three-line class="py-0 my-0">
                            <v-list-item-content class="py-0 my-0">
                              <v-list-item-subtitle class="my-0 py-0">
                                <small>Linea: {{ item.producto.linea.descripcion }}</small>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                <small>SubLinea: {{ item.producto.subLinea.descripcion }}</small>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </td>
                        <td v-if="columnas.includes('EXISTENCIA')">
                          <p class="py-0 my-0" v-if="item.lote && item.lote != undefined && item.lote.existenciaLote">
                            <small :class="item.lote.existenciaLote.inventario > 0 ? '' : 'error--text'">
                              {{ item.lote.existenciaLote.inventario }}
                            </small>
                          </p>

                          <p class="py-0 my-0" v-else>
                            <small :class="item.existencia.inventario > 0 ? 'success--text' : 'error--text'">
                              {{ item.existencia.inventario }}
                            </small>
                          </p>
                        </td>
                        <td class="text-right">
                          {{ funciones.monedaPrincipal().simbolo }} {{ item.pvp.toFixed(2) }}
                          <br />

                          <small v-if="item.producto.tasaImpuesto.valor > 0"
                            >+iva:{{ funciones.monedaPrincipal().simbolo }}
                            {{ (item.pvp * (1 + item.producto.tasaImpuesto.valor / 100)).toFixed(2) }}</small
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-card>
          </v-col>
          <v-col lg="4" v-if="seleccionMultiple" > 
            <v-card >
              <v-card-subtitle class="mb-5">Producto Seleccionados</v-card-subtitle>
              <template>
                <v-simple-table fixed-header style="height: calc(100vh - 250px)" dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">#</th>
                        <th class="text-left">Producto</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in datosSelect" :key="i">
                        <td>{{ item.producto.id }}</td>
                        <td>
                          <p class="py-0 my-0">
                            <small>{{ item.producto.nombre }}</small>
                          </p>
                          <p class="py-0 my-0" v-if="item.lote">
                            <strong
                              >Lote: {{ item.lote.numeroLote }}
                              {{ funciones.formatoFecha(item.lote.fechaVencimiento) }}</strong
                            >
                          </p>
                          <p class="py-0 my-0">
                            <small>Barra: {{ item.producto.barra }}</small>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    
  </div>
</template>
<script>
import UnidadMedidaSelect from '@/views/sistema/producto/maestro/unidad-medida/componentes/Select.vue'
import MarcaSelect from '@/views/sistema/producto/maestro/marca/componentes/Select.vue'
import ModeloSelect from '@/views/sistema/producto/maestro/modelo/componentes/Select.vue'
import ColorSelect from '@/views/sistema/producto/maestro/color/componentes/Select.vue'
import CategoriaSelect from '@/views/sistema/producto/maestro/categoria/componentes/Select.vue'
import SubCategoriaSelect from '@/views/sistema/producto/maestro/sub-categoria/componentes/Select.vue'
import LineaSelect from '@/views/sistema/producto/maestro/linea/componentes/Select.vue'
import SubLineaSelect from '@/views/sistema/producto/maestro/sub-linea/componentes/Select.vue'
import PaisSelect from '@/views/sistema/producto/maestro/pais/componentes/Select.vue'
import TipoOrigenProdcutoSelect from '@/views/sistema/producto/maestro/tipo-origen-producto/componentes/Select.vue'
import TipoProdcutoSelect from '@/views/sistema/producto/maestro/tipo-producto/componentes/Select.vue'
import TasaImpuestoSelect from '@/views/sistema/producto/maestro/tasa-impuesto/componentes/Select.vue'
import ProveedorSelect from '@/views/sistema/producto/catalogo/proveedor/componentes/Select.vue'
import { mdiFilterOff, mdiFilter } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import ProductoSucursalServices from '@/api/servicios/ProductoSucursalServices'
import store from '@/store'
import funciones from '@/funciones/funciones'

export default {
  components: {
    UnidadMedidaSelect,
    MarcaSelect,
    ModeloSelect,
    ColorSelect,
    CategoriaSelect,
    SubCategoriaSelect,
    LineaSelect,
    SubLineaSelect,
    PaisSelect,
    TipoOrigenProdcutoSelect,
    TipoProdcutoSelect,
    TasaImpuestoSelect,
    ProveedorSelect,
  },
  props: {
    seleccionMultiple: Boolean,
    columnas: {
      type: Array,
      default: ['ID', 'NOMBRE', 'CODIGO', 'CARACTERISTICA', 'CATEGORIA', 'LINEA', 'EXISTENCIA'],
    },
    IndExistencia: {
      type: Boolean,
      default: true,
    },
    soloExistencia: {
      type: Boolean,
      default: true,
    },
    idArea: {
      type: Number,
      default: -1,
    },
    indLote: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    '$vuetify.theme.dark': function (news, olds) {
      this.dark = news
    },
  },

  setup(props, context) {
    const dato = ref({
      IdTipoOrigenProducto: null,
      IdTipoProducto: null,
      IdUnidadMedida: null,
      IdModelo: null,
      IdMarca: null,
      IdColor: null,
      IdCategoria: null,
      IdSubCategoria: null,
      IdLinea: null,
      IdSubLinea: null,
      IdPais: null,
      Nombre: '',
      IndPeso: null,
      IdTasaImpuesto: null,
    })
    const showFiltros = ref(false)
    const showOnlyExistencia = ref(true)
    const desserts = []
    const NombreRef = ref(null)
    const dark = false
    const limit = ref(20)
    const offsetTop = ref(0)
    const offsetHeight = ref(0)
    const onScroll = e => {
      offsetHeight.value = e.target.scrollHeight
      offsetTop.value = (e.target.scrollTop + 400).toFixed()
      if (e.target.scrollHeight - 200 <= (e.target.scrollTop + 400).toFixed()) {
        limit.value = limit.value + 20
      }
    }

    const colorRow = (item, i) => {
      let clas = ''
      //item.checked ? ' ':' info' + !(i % 2) && !item.checked ? 'grey3' : ' ' " @click="item.checked = !item.checked
      if (!(i % 2) && !item.checked) {
        if (!dark.value) {
          clas = 'grey3 '
        }
      }

      if (item.checked) {
        clas = 'successLight'
      }
      return clas
    }

    watch(props, () => {
      showOnlyExistencia.value = props.soloExistencia
    })
    const datos = ref([])
    const datosSelect = ref([])
    const buscando = ref(false)

    const filterProductos = () => {
      if (showOnlyExistencia.value == true) {
        const datoss = datos.value.filter(item => {
          let _existencia = false
          if (item.existencia.inventario > 0) {
            _existencia = true
          } else {
            _existencia = false
          }
          if (item.lote != undefined) {
            if (item.lote.existenciaLote?.inventario > 0) {
              _existencia = true
            } else {
              _existencia = false
            }
          }

          return _existencia
        })

        if (datoss.length > 0) {
          return datoss.slice(0, limit.value)
        } else {
          return []
        }
      } else {
        const datoss = datos.value
        if (datoss.length > 0) {
          return datoss.slice(0, limit.value)
        } else {
          return []
        }
        return datos.value
      }
    }
    const selectProducto = item => {
      if (props.IndExistencia == true) {
        let _existencia = false
        if (item.existencia.inventario > 0) {
          _existencia = true
        } else {
          _existencia = false
        }
        if (item.lote != undefined) {
          if (item.lote.existenciaLote?.inventario > 0) {
            _existencia = true
          } else {
            _existencia = false
          }
        }
        if (_existencia == true) {
          item.checked = !item.checked
          if (item.checked) {
            if (datosSelect.value.filter(i => i.codigoLote == item.codigoLote).length > 0) {
              store.commit('setAlert', {
                message: 'El producto ya se encuentra seleccionado',
                type: 'info',
              })
            } else {
              datosSelect.value.push({ ...item })
            }
          } else {
            datosSelect.value = datosSelect.value.filter(i => i.codigoLote != item.codigoLote)
          }
        } else {
          store.commit('setAlert', {
            message: 'No tiene existencia disponible',
            type: 'error',
          })
        }
      } else {
        item.checked = !item.checked
        if (item.checked) {
          if (datosSelect.value.filter(i => i.codigoLote == item.codigoLote).length > 0) {
            store.commit('setAlert', {
              message: 'El producto ya se encuentra seleccionado',
              type: 'info',
            })
          } else {
            datosSelect.value.push({ ...item })
          }
        } else {
          datosSelect.value = datosSelect.value.filter(i => i.codigoLote != item.codigoLote)
        }
      }
    }
    const GetProductos = () => {
      context.emit('GetProductos', datosSelect.value)
    }
    const Limpiar = () => {
      datos.value = []
      datosSelect.value = []
      NombreRef.value.focus()
    }

    const buscar = () => {
      buscando.value = true
      ProductoSucursalServices.ConsultarAvanzada({
        ...dato.value,
        IdSucursal: store.state.sucursalSelect.id,
        idArea: props.idArea,
        indLote: props.indLote,
      })
        .then(response => {
          if (response.data.estatus) {
            if (response.data.datos.length > 0) {
              datos.value = response.data.datos
            } else {
              store.commit('setAlert', {
                message: 'No hubo resultados',
                type: 'info',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
            message: err,
            type: 'error',
          })
        })
        .finally(() => {
          buscando.value = false
        })
    }

    return {
      dato,
      desserts,
      showFiltros,
      icons: {
        mdiFilterOff,
        mdiFilter,
      },
      buscar,
      datos,
      datosSelect,
      buscando,
      funciones,
      colorRow,
      selectProducto,
      GetProductos,
      Limpiar,
      NombreRef,
      showOnlyExistencia,
      filterProductos,
      offsetTop,
      onScroll,
      offsetHeight,
      dark,
    }
  },
}
</script>