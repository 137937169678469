<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table dense>
        <template>
          <tbody>
            <tr>
              <td class="text-left"><small>Sub total :</small></td>
              <td class="text-left"  v-if="visualizarMoneda.includes('PRINCIPAL')">
                <small>{{ monedaPrincipal.simbolo }}.{{ factura.montoSubTotal.toFixed(2) }}</small>
              </td>
              <td class="text-left"  v-if="visualizarMoneda.includes('PAIS')" >
                <small> {{ monedaPais.simbolo }}.{{ factura.montoSubTotalPais.toFixed(2) }}</small>
              </td>
            </tr>

            <tr>
              <td class="text-left"><small>Descuento:</small></td>
              <td class="text-left"  v-if="visualizarMoneda.includes('PRINCIPAL')">
                <small>{{ monedaPrincipal.simbolo }}.{{ factura.montoDescuento.toFixed(2) }}</small>
              </td>
              <td class="text-left" v-if="visualizarMoneda.includes('PAIS')">
                <small> {{ monedaPais.simbolo }}.{{ factura.montoDescuentoPais.toFixed(2) }}</small>
              </td>
            </tr>
            <tr>
              <td class="text-left"><small>IVA:</small></td>
              <td class="text-left"  v-if="visualizarMoneda.includes('PRINCIPAL')">
                <small>{{ monedaPrincipal.simbolo }}.{{ factura.montoIva.toFixed(2) }}</small>
              </td>
              <td class="text-left" v-if="visualizarMoneda.includes('PAIS')">
                <small> {{ monedaPais.simbolo }}.{{ factura.montoIvaPais.toFixed(2) }}</small>
              </td>
            </tr>
            <tr>
              <td class="text-left"><small>IGTF:</small></td>
              <td class="text-left"  v-if="visualizarMoneda.includes('PRINCIPAL')">
                <small>{{ monedaPrincipal.simbolo }}.{{ factura.montoIgtf.toFixed(2) }}</small>
              </td>
              <td class="text-left" v-if="visualizarMoneda.includes('PAIS')">
                <small> {{ monedaPais.simbolo }}.{{ factura.montoIgtfPais.toFixed(2) }}</small>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>
<script>
import FuncionesGenerales from '@/funciones/funciones';
export default {
  props: {
    factura: Object,
    visualizarMoneda:Array
  },
  setup() {
    const monedaPais = FuncionesGenerales.monedaPais()
    const monedaPrincipal = FuncionesGenerales.monedaPrincipal()

    return {
      monedaPais,
      monedaPrincipal
    }
  },
}
</script>