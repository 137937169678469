<template>

    <div>

        <v-autocomplete ref="autocompleteRef" :rules="rules" :prepend-icon="icon ? icons.mdiAccount : null" v-model="select"
            :items="items" :filter="customFilter" :loading="isLoading" clearable :search-input.sync="search"
            hide-details hide-selected item-text="nombre" item-value="id" :label="label" outlined dense >
            <template v-slot:no-data>
                <v-list-item>
                    <v-list-item-title class="text-center">
                        No hay coincidencia
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title class="text-center" @click="console.log('ass')">
                        <v-btn @click="abrirModal()" block color="primary">Nuevo cliente</v-btn>
                    </v-list-item-title>
                </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
                <span>{{ item.nombre }} {{ item.apellido }}</span>
            </template>
            <template v-slot:item="{ item }">
                <v-list-item-avatar color="primary" size="30" class=" my-2 py-2 font-weight-light white--text">
                    <small>{{ item.nombre.charAt(0) }}{{ item.apellido.charAt(0) }}</small>
                </v-list-item-avatar>
                
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.nombre }} {{ item.apellido }}
                        <br>
                        <small> {{ item.documento }}</small>
                    </v-list-item-title>

                </v-list-item-content>
              
            </template>
            
        </v-autocomplete>
        <modal @GetCliente="GetCliente" ref="modalRef" :btnVisible="false"></modal>
    </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAccount } from '@mdi/js'
import ClienteServices from '@/api/servicios/ClienteServices'
import Modal from './Modal.vue'
export default {
    components: {
        Modal
    },
    props: {
        clearable: Boolean,
        rules: Array,
        icon: Boolean,
        label: {
            type:String,
            default:" Buscar cliente F4"
        },
        value:Number,
    },

    setup(props, context) {
        const modalRef = ref(null)
        const autocompleteRef = ref(null)
        const dialog = ref(false)
        const isLoading = ref(false)
        const items = ref([])
        const select = ref(null)
        const search = ref(null)
        const tab = ref(null)
        const customFilter = (item, queryText, itemText) => {
            const filtro1 = item.documento.toUpperCase()
            const filtro2 = item.nombre.toUpperCase()
            const filtro3 = item.apellido.toUpperCase()
            const filtro4 = (item.apellido + ' ' + item.nombre).toUpperCase()
            const searchText = queryText.toUpperCase()

            return filtro1?.indexOf(searchText) > -1 ||
                filtro2?.indexOf(searchText) > -1 ||
                filtro3?.indexOf(searchText) > -1 ||
                filtro4?.indexOf(searchText) > -1
        }

        watch(props, () => {
            select.value = props.value
        })
        onBeforeMount(() => {
           
        if (props.value != undefined) {
            select.value = props.value
        }
        })

        watch(select, () => {
            //alert("select")
            context.emit('GetResult', items.value.find(item => (item.id == select.value)))
        }

        )

        const GetCliente = (item)=>{
            //items.value.push(item)
            //context.emit('Getcliente', item)
            search.value = item.documento
            Buscar(true)
        }
        
        watch(search, () => {
            Buscar()
        }

        )
        const abrirModal = ()=>{
            modalRef.value.abrir()
        }

        const focus = () => {
            autocompleteRef.value.focus()
        }

        const Buscar = () => {
            // Items have already been loaded
           
            if (isLoading.value == true)
                return

            isLoading.value = true
            setTimeout(() => {

                ClienteServices.ConsultarBuscar({ valor: search.value })
                    .then(response => {
                        if (response.data.estatus == true) {
                            items.value = response.data.datos
                        } else {
                            items.value = []
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => (
                        isLoading.value = false
                    ))
            }, 1000);
        }


        return {
            icons: {
                mdiAccount
            },
            dialog,
            isLoading,
            items,
            select,
            search,
            tab,
            customFilter,
            autocompleteRef,
            focus,
            modalRef,
            abrirModal,
            GetCliente
        }
    },
}
</script>