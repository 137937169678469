//import { axios } from '@/api';Categoria
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class TipoDireccionServices {
     Consultar(
        datos = {
            Id: -1,
            Descripcion: '',
            indActivo:null
        }
    ) {
        return http.get("tipodireccion/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }   
   
}


export default new TipoDireccionServices();