<template>
    <v-card>
        <v-card-subtitle>Cliente</v-card-subtitle>
        <v-card-text>
            <v-form ref="form" lazy-validation>
                <v-row>
                    <v-col cols="6" class="mb-0 pb-0">
                        <tipoDocumento v-model="dato.tipoDocumento" :dense="true" :outlined="true" />

                    </v-col>
                    <v-col cols="6" class="mb-0 pb-0">
                        <v-text-field :prefix="`${dato.tipoDocumento ? dato.tipoDocumento.abreviatura : ''}-`"
                            :rules="reglas.documento" v-model="dato.documento" :label="'Documento *'" required outlined
                            dense hide-details="auto" class="mb-6 "></v-text-field>
                    </v-col>
                    <v-col cols="6" class="my-0 py-0" v-if="dato.tipoDocumento">
                        <v-text-field :rules="reglas.nombre" v-model="dato.nombre"
                            :label="dato.tipoDocumento.idTipoEntidad == 1 ? 'Nombre *' : 'Razón social *'" required
                            outlined dense hide-details="auto" class="mb-6 "></v-text-field>
                    </v-col>
                    <v-col cols="6" class="my-0 py-0" v-if="dato.tipoDocumento">
                        <v-text-field :rules="reglas.apellido" v-model="dato.apellido"
                            :label="dato.tipoDocumento.idTipoEntidad == 1 ? 'Apellido *' : 'Razón comercial *'" required
                            outlined dense hide-details="auto" class="mb-6 "></v-text-field>
                    </v-col>
                    <v-col cols="6" class="my-0 py-0">
                        <v-text-field-simplemask class="" v-model="dato.telefono" v-bind:label="'Telefono'"
                            v-bind:properties="{
                                outlined: true,
                                clearable: false,
                                dense: true,
                                rules: reglas.telefono,
                            }" v-bind:options="{
    inputMask: '(####) ### ## ##',
    outputMask: '###########',
    empty: null,
    applyAfter: false,
    alphanumeric: false,
    lowerCase: true,
}" />
                    </v-col>
                    <v-col cols="6" class="my-0 py-0">
                        <v-text-field-simplemask class="" v-model="dato.telefono2" v-bind:label="'Telefono'"
                            v-bind:properties="{
                                outlined: true,
                                clearable: false,
                                dense: true,
                                rules: reglas.telefono2,
                            }" v-bind:options="{
    inputMask: '(####) ### ## ##',
    outputMask: '###########',
    empty: null,
    applyAfter: false,
    alphanumeric: false,
    lowerCase: true,
}" />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-subtitle v-if="showDireccion"  >Direcciones <v-btn color="primary" x-small
                @click="AbrirDireccion(null)">Nuevo</v-btn>
        </v-card-subtitle>
        <v-card-text v-if="showDireccion" >
            <v-simple-table fixed-header max-height="250px">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                #
                            </th>
                            <th class="text-left">
                                Tipo
                            </th>
                            <th class="text-left">
                                Pais
                            </th>
                            <th class="text-left">
                                Estado
                            </th>
                            <th class="text-left">
                                Municipio
                            </th>
                            <th class="text-left">
                                Ciudad
                            </th>
                            <th class="text-left">
                                Avenida
                            </th>
                            <th class="text-left">
                                Calle
                            </th>
                            <th class="text-left">
                                Edificio
                            </th>
                            <th class="text-left">
                                Piso
                            </th>
                            <th class="text-left">
                                Apto/Casa
                            </th>
                            <th class="text-left">
                                Extendida
                            </th>
                            <th class="text-left">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in dato.direcciones" :key="index">
                            <td>
                                <v-chip color="success" draggable x-small v-if="item.id < 0">
                                    Nuevo
                                </v-chip>
                                <v-chip color="info" draggable x-small v-else>
                                    {{ item.id }}
                                </v-chip>
                            </td>
                            <td> {{ item.tipoDireccion.descripcion }}</td>
                            <td> {{ item.pais.nombre }}</td>

                            <td> {{ item.estado.nombre }}</td>
                            <td> {{ item.municipio.nombre }}</td>
                            <td>
                                {{ item.ciudad.nombre }}
                            </td>
                            <td>
                                {{ item.avenida }}
                            </td>
                            <td>
                                {{ item.calle }}
                            </td>
                            <td>
                                {{ item.edificio }}
                            </td>
                            <td>
                                {{ item.piso }}
                            </td>
                            <td>
                                {{ item.apartamento }}
                            </td>
                            <td>
                                {{ item.direccionExtendida }}
                            </td>
                            <td> <v-btn x-small @click="AbrirDireccion(item)"> Modificar</v-btn> </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="text-right">
            <v-btn class="secondary mr-1" small @click="limpiar()">Limpiar</v-btn>
            <v-btn class="primary" small @click="Guardar()">Guardar</v-btn>
        </v-card-text>
        <ModalDireccion ref="ModalDireccionRef" @GetDireccion="GetDireccion" :BntVisible="false"></ModalDireccion>
    </v-card>

</template>
<script>
import tipoDocumento from '@/views/sistema/maestro/tipo-documento/componentes/SelectJson.vue'
import ModalDireccion from '@/views/sistema/maestro/direccion/componentes/Modal.vue'
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import ClienteServices from '@/api/servicios/ClienteServices'
import goTo from 'vuetify/lib/services/goto'
export default {
    components: {
        tipoDocumento,
        ModalDireccion
    },
    props: {
        showDireccion: Boolean
    },
    setup(props,contex) {
        const ModalDireccionRef = ref(null)
        let identity = -10000
        const identityGlobal = () => {
            identity = identity + 1
            return identity
        }
        const form = ref(null)
        const guardando = ref(false)
        const clienteInit = {
            id: -1/*int*/,
            idTipoDocumento: null/*int*/,
            documento: ""/*int*/,
            nombre: ""/*string*/,
            apellido: ""/*string*/,
            telefono: ""/*string*/,
            telefono2: ""/*string*/,
            direcciones: [],
            tipoDocumento: { "id": 1, "idTipoEntidad": 1 }
        }

        const validarForm = () => {
            let val = form.value?.validate()
            return val
        }
        onBeforeMount(() => {
            limpiar()
        })
        const ActualizarLista = item => {
            console.log(item)
            contex.emit('GetActualizarLista', { ...item })

        }
        const dato = ref({ ...clienteInit })
        const reglas = {
            documento: [
                v => !!v || "descripcion es requerido",
                v => (dato.value.tipoDocumento.idTipoEntidad == 1 ? /^(\d{6,9})$/.test(v) || "La cedula debe ser valida: ej 12365450" : true),
                v => (dato.value.tipoDocumento.idTipoEntidad == 2 ? /^(\d{5,9}[\-]\d{1})$/.test(v) || "El Rif debe ser valido: ej J-12345678-0" : true),
            ],

            idTipoDocumento: [
                v => !!v || "Nombre es requerido", 
            ],

            nombre: [
                v => !!v || "Es requerido",
                v => (v && v.length <= 50 && v.length >= 3) || "debe ser valido",
            ],
            apellido: [
                v => !!v || "Es requerido",
                v => (v && v.length <= 50 && v.length >= 3) || "debe ser valido",
            ],

            telefono2: [
                v => (!!v ? /(\(0414|0412|0416|0426|0424)?[ -]*([0-9][ -]*){3}([0-9][ -]*){2}[ -]*([0-9][ -]*){2}$/.test(v) || "Teléfono Invalida, Ej: (0412) 123 12 12" : true),
            ],
            telefono: [
                v => (!!v ? /(\(0414|0412|0416|0426|0424)?[ -]*([0-9][ -]*){3}([0-9][ -]*){2}[ -]*([0-9][ -]*){2}$/.test(v) || "Teléfono Invalida, Ej: (0412) 123 12 12" : true),
            ],

        };
        const GetDireccion = (datoin) => {
            if (datoin.id == -1) {
                dato.value.direcciones.push({ ...datoin, id: identityGlobal() })
            } else {
                const index = dato.value.direcciones.findIndex(i => i.id == datoin.id);

                dato.value.direcciones.splice(index, 1, datoin);
            }

        }
        const AbrirDireccion = (item = null) => {
            console.log("AbrirDireccion")
            console.log(item)
            ModalDireccionRef.value.Abrir({ ...item })
        }
        const CargarEditar = (item) => {
            let direcciones = []
            if (item.direcciones) {
                item.direcciones.forEach(element => {
                    direcciones.push({
                        ...element,
                        idPais:element.ciudad.municipio.estado.pais.id,
                        idEstado:element.ciudad.municipio.estado.id,
                        idMunicipio:element.ciudad.municipio.id,
                        pais: element.ciudad.municipio.estado.pais,
                        estado: element.ciudad.municipio.estado,
                        municipio: element.ciudad.municipio,
                    })
                });
            }


            dato.value = { ...item, direcciones }
            goTo(0)
        }
        const limpiar = () => {
            dato.value = { ...clienteInit, direcciones: [], tipoDocumento: { "id": 1, "idTipoEntidad": 1 } }

        }

        const Guardar = () => {
            if (validarForm()) {

                try {

                    ClienteServices.Actualizar(
                        store.state.user.idUsuario,
                        {...dato.value, idTipoDocumento:dato.value.tipoDocumento.id}
                    ).then(response => {
                        console.log(response)
                        if (response.data.estatus == true) {
                            store.commit('setAlert', {
                                message: response.data.mensaje,
                                type: 'success',
                            })
                            ActualizarLista(response.data.datos.datos)
                            limpiar()
                        } else {

                            store.commit('setAlert', {
                                message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                                type: 'warning',
                            })
                        }
                    })
                        .catch(error => {
                            console.log(error)
                            store.commit('setAlert', {
                                message: error,
                                type: 'error',
                            })
                        })
                        .finally(() => {
                            guardando.value = false
                        })
                } catch (error) {
                    ////alert(error)
                    store.commit('setAlert', {
                        message: error,
                        type: 'error',
                    })
                }
            }
        }
        return {
            dato,
            reglas,
            ModalDireccionRef,
            AbrirDireccion,
            GetDireccion,
            form,
            Guardar,
            limpiar,
            CargarEditar
        }
    },
}
</script>