<template>
  <div>
    <v-simple-table fixed-header height="calc(67vh - 100px)" ref="table">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Producto</th>
            <th class="text-right">Cantidad</th>
            <th class="text-right">Precio</th>
            <th class="text-right">SubTotal</th>
            <th class="text-right">Descuento</th>
            <th class="text-right">Iva</th>
            <th class="text-right">Total</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in detalles" :key="index">
            <td @click="abrirModal(item)">
              <div class="d-flex align-center">
                <div class="d-flex flex-column">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <span
                          v-if="item.producto.producto.nombre.length < 30"
                          class="d-block font-weight-semibold text--primary text-truncate"
                        >
                          {{ FuncionesGenerales.camelCase(item.producto.producto.nombre) }}
                        </span>
                          
                        <span v-else class="d-block font-weight-semibold text--primary text-truncate">
                          {{ FuncionesGenerales.camelCase(item.producto.producto.nombre.substring(0, 27)) }}...
                        </span>
                      </span>
                    </template>
                    <span> {{ FuncionesGenerales.camelCase(item.producto.producto.nombre) }}</span>
                  </v-tooltip>
                  <small v-if="item.idLote">
                    Lote: {{ FuncionesGenerales.camelCase(item.producto.lote[0].numeroLote) }}
                  </small>
                  <small> Barra: {{ FuncionesGenerales.camelCase(item.producto.producto.barra) }} </small>
                </div>
              </div>
            </td>
            <td @click="abrirModal(item)" class="text-right">{{ item.numeroUnidades.toFixed(2) }}</td>
            <td @click="abrirModal(item)" class="text-right">
              <small
                class="d-block font-weight-semibold text--primary text-truncate"
                v-if="visualizarMoneda.includes('PRINCIPAL')"
              >
                {{ monedaPrincipal.simbolo }}{{ item.pvp.toFixed(2) }}
              </small>
              <small class="primary--text" v-if="visualizarMoneda.includes('PAIS')">
                {{ monedaPais.simbolo }}{{ item.pvpPais.toFixed(2) }}
              </small>
            </td>
            <td @click="abrirModal(item)" class="text-right">
              <small
                class="d-block font-weight-semibold text--primary text-truncate"
                v-if="visualizarMoneda.includes('PRINCIPAL')"
              >
                {{ monedaPrincipal.simbolo }}{{ (item.pvp * item.numeroUnidades).toFixed(2) }}
              </small>
              <small v-if="visualizarMoneda.includes('PAIS')" class="primary--text">
                {{ monedaPais.simbolo }}{{ (item.pvpPais * item.numeroUnidades).toFixed(2) }}
              </small>
            </td>
            <td @click="abrirModal(item)" class="text-right">
              <small
                class="d-block font-weight-semibold text--primary text-truncate"
                v-if="visualizarMoneda.includes('PRINCIPAL')"
              >
                {{ monedaPrincipal.simbolo }}{{ item.descuento.toFixed(2) }}
              </small>
              <small v-if="visualizarMoneda.includes('PAIS')" class="primary--text">
                {{ monedaPais.simbolo }}{{ item.descuentoPais.toFixed(2) }}
              </small>
            </td>
            <td @click="abrirModal(item)" class="text-right">
              <small
                class="d-block font-weight-semibold text--primary text-truncate"
                v-if="visualizarMoneda.includes('PRINCIPAL')"
              >
                {{ monedaPrincipal.simbolo }}{{ item.iva.toFixed(2) }}
              </small>
              <small v-if="visualizarMoneda.includes('PAIS')" class="primary--text">
                {{ monedaPais.simbolo }}{{ item.ivaPais.toFixed(2) }}
              </small>
            </td>
            <td @click="abrirModal(item)" class="text-right">
              <small
                class="d-block font-weight-semibold text--primary text-truncate"
                v-if="visualizarMoneda.includes('PRINCIPAL')"
              >
                {{ monedaPrincipal.simbolo }}{{ item.total.toFixed(2) }}
              </small>
              <small v-if="visualizarMoneda.includes('PAIS')" class="primary--text">
                {{ monedaPais.simbolo }}{{ item.totalPais.toFixed(2) }}
              </small>
            </td>
            <td>
              <v-btn x-small icon color="error" @click="eliminar(item)">
                <v-icon>{{ icons.mdiDelete }}</v-icon>
              </v-btn>
              <v-btn
                v-if="item.lotes && item.idLote == null"
                small
                icon
                density="default"
                outlined
                @click="buscarLote(item, index)"
                color="secondary"
              >
                <v-icon>{{ icons.mdiAlphaL }}</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <ModalBuscarLote :detallesProd="detalles" ref="BuscarLoteRef"></ModalBuscarLote>
  </div>
</template>
<script>
import goTo from 'vuetify/lib/services/goto'
import FuncionesGenerales from '@/funciones/funciones'
import { ref, watch } from '@vue/composition-api'
import { mdiAlphaL, mdiDelete } from '@mdi/js'
import ModalBuscarLote from './ModalBuscarLote.vue'
export default {
  components: {
    ModalBuscarLote,
  },
  props: {
    detalles: Array,
    visualizarMoneda: Array,
  },
  setup(props, context) {
    const BuscarLoteRef = ref(null)
    const table = ref(null)
    const monedaPais = FuncionesGenerales.monedaPais()
    const monedaPrincipal = FuncionesGenerales.monedaPrincipal()
    const indAbrir = ref(false)
    const objectoProducto = ref({})
    watch(props, () => {
      console.log(table.value)
      goTo(0, { container: table.value })
    })
    const abrirModal = item => {
      context.emit('modificarDetalle', item)
    }
    const eliminar = item => {
      context.emit('eliminar', item) 
    }
    // const buscarLote = (dato) => {
    //     BuscarLoteRef.value.buscarLoteProducto(dato);
    // }
    const buscarLote = (dato, index) => {
      objectoProducto.value = dato
      BuscarLoteRef.value.listarLote({ dato: dato, index: index })
    }
    return {
      FuncionesGenerales,
      abrirModal,
      buscarLote,
      table,
      objectoProducto,
      indAbrir,
      monedaPais,
      monedaPrincipal,
      icons: { mdiAlphaL, mdiDelete },
      BuscarLoteRef,
      eliminar,
    }
  },
}
</script>